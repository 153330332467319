import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Components/Context/AuthContext";
import { UjianProvider } from "./Components/Context/UjianContext";
import ErrorBoundary from "./Components/Context/ErrorBoundary";
import axios from "axios";
import MainLayout from "./Components/Layouts/MainLayout";
import DetailLayout from "./Components/Layouts/DetailLayout";
import DataLayout from "./Components/Layouts/DataLayout";
import AuthenticationLayout from "./Components/Layouts/AuthenticationLayout";
import Loading from "./Components/Layouts/Components/Loading";
import DataPeserta from "./Components/Guru/DataPeserta/Content";
import Home from "./Components/Guru/Content";
import HomeSiswa from "./Components/Siswa/Content";
import BankSoal from "./Components/Guru/BankSoal/Content";
import ControlSoal from "./Components/Guru/BankSoal/ControlSoal";
import DaftarUjian from "./Components/Guru/DaftarUjian/Content";
import MonitorUjian from "./Components/Guru/MonitorUjian/Content";
import Login from "./Components/Authentication/Login";
import ProtectedRoute from "./Components/Authentication/ProtectedRoute";
import DetailSoal from "./Components/Guru/BankSoal/DetailSoal";
import DetailMonitor from "./Components/Guru/MonitorUjian/DetailMonitor";
import DetailUjian from "./Components/Siswa/DetailUjian";
import HalamanUjian from "./Components/Siswa/HalamanUjian";
import Ujian from "./Components/Siswa/Ujian";
import RiwayatUjian from "./Components/Guru/RiwayatUjian/Content";
import Selesai from "./Components/Siswa/Components/Selesai"


const userType = localStorage.getItem("user_type"); // Ambil tipe pengguna
axios.defaults.baseURL = "https://smamutas.sch.id/api";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "auth_token"
)}`;
axios.defaults.withCredentials = true;

function App() {
  return (
    <Router>
      <AuthProvider>
      <UjianProvider>
       <ErrorBoundary>
        <Routes>
          <Route
            path="/login"
            element={
              <AuthenticationLayout>
                <Login />
              </AuthenticationLayout>
            }
          />
          <Route
            path="/data-peserta"
            element={
              <ProtectedRoute>
                <DataLayout>
                  <DataPeserta />
                </DataLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <MainLayout>
                    {userType === "siswa" ? <HomeSiswa /> : <Home />}
                  </MainLayout>
                </ProtectedRoute>
              </React.Suspense>
            }
          />

          <Route
            path="/bank-soal"
            element={
              <ProtectedRoute>
                <DataLayout>
                  <BankSoal />
                </DataLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/bank-soal/detail/:id"
            element={
              <ProtectedRoute>
                <DetailLayout>
                  <DetailSoal />
                </DetailLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/bank-soal/tambah/:id"
            element={
              <ProtectedRoute>
                <DetailLayout>
                  <ControlSoal />
                </DetailLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/bank-soal/edit/:id/:soalId"
            element={
              <ProtectedRoute>
                <DetailLayout>
                  <ControlSoal />
                </DetailLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/daftar-ujian"
            element={
              <ProtectedRoute>
                <DataLayout>
                  <DaftarUjian />
                </DataLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/riwayat-ujian"
            element={
              <ProtectedRoute>
                <DataLayout>
                  <RiwayatUjian />
                </DataLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/monitor-ujian"
            element={
              <ProtectedRoute>
                <DataLayout>
                  <MonitorUjian />
                </DataLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/monitor-ujian/detail/:id"
            element={
              <ProtectedRoute>
                <DetailLayout>
                  <DetailMonitor />
                </DetailLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/ujian/peraturan/:id"
            element={
              <ProtectedRoute>
                <Ujian />
              </ProtectedRoute>
            }
          />
          
           <Route
            path="/ujian/selesai"
            element={
              <ProtectedRoute>
                <Selesai />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ujian/:id"
            element={
              <ProtectedRoute>
                <HalamanUjian />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ujian/detail/:id"
            element={
              <ProtectedRoute>
                <DetailLayout>
                  <DetailUjian />
                </DetailLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
         </ErrorBoundary>
        </UjianProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
