import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/id";

const RiwayatUjian = () => {
  const [daftarUjian, setDaftarUjian] = useState([]);

  // Fetch daftar ujian awal
  const fetchData = async () => {
    try {
      const response = await axios.get("/daftarujian");
      setDaftarUjian(response.data.daftar_ujian);
    } catch (error) {
      Swal.fire("Error", "Gagal memuat data ujian.", "error");
    }
  };

  // Fungsi untuk menampilkan data ujian lebih detail
  const lihatData = async (id) => {
    try {
      // Ambil data pernyataan, jawaban siswa, dan nilai bobot berdasarkan ID ujian
      const response = await axios.get(`/daftarujian/data-riwayat/${id}`);
      const data = response.data.data;

      // Menampilkan data peserta dalam SweetAlert
      let tabelPeserta = `
        <table class="table table-bordered text-center">
          <thead class="thead-dark">
            <tr>
              <th>Nama Siswa</th>
              <th>NISN</th>
              <th>Soal</th>
              <th>Jawaban Siswa</th>
              <th>Kunci Jawaban</th>
              <th>Nilai Bobot</th>
            </tr>
          </thead>
          <tbody>
      `;

      // Menambahkan baris untuk setiap peserta
      data.forEach((item) => {
        tabelPeserta += `
          <tr>
            <td>${item.nama}</td>
            <td>${item.nisn}</td>
            <td>${item.nama_soal}</td>
            <td>${item.jawaban_siswa}</td>
            <td>${item.kunci_jawaban}</td>
            <td>${item.nilai_bobot}</td>
          </tr>
        `;
      });

      tabelPeserta += `</tbody></table>`;

      // Menampilkan tabel di dalam SweetAlert
      Swal.fire({
        title: "Data Peserta Ujian",
        html: tabelPeserta,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        width: "80%",
        customClass: {
          popup: "overflow-auto", // Untuk memastikan tabel dapat digulirkan jika terlalu panjang
        },
      });
    } catch (error) {
      Swal.fire("Error", "Gagal mengambil data peserta ujian.", "error");
    }
  };

  // Jalankan fetchData saat komponen pertama kali dimuat
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="col-md-12 col-sm-12">
      <div className="left-section-inner">
        <div className="text-center mb-2">
          <div className="main-heading text-center mb-3">
            <span className="highlighted">Riwayat Ujian</span>
          </div>
          <p>
            Lihat data riwayat ujian yang telah selesai dilaksanakan dan
            divalidasi oleh operator.
          </p>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered text-center">
            <thead className="thead-dark">
              <tr>
                <th>Kelas</th>
                <th>Peserta</th>
                <th>Soal</th>
                <th>Waktu</th>
                <th>Durasi</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {daftarUjian.length > 0 ? (
                daftarUjian.map((ujian) => (
                  <tr key={ujian.id}>
                    <td>{ujian.kelas}</td>
                    <td>{ujian.jumlah_peserta} Siswa</td>
                    <td>
                      {ujian.bank_soal
                        ? `${ujian.bank_soal.nama_soal} (${ujian.bank_soal.mata_pelajaran})`
                        : "Tidak ada soal"}
                    </td>
                   <td>
  {moment(ujian.tanggal_ujian).locale('id').format('D MMMM YYYY')}, <br />
  Pukul {moment(ujian.waktu_ujian, 'HH:mm:ss').format('HH:mm')} WIB
</td>
                    <td>{ujian.durasi_terformat}</td>

                    <td>
                      <button
                        className="btn btn-sm btn-primary me-1"
                        onClick={() => lihatData(ujian.id)} // Panggil fungsi lihatData
                      >
                        Lihat Data
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    Tidak ada riwayat ujian yang telah dilaksanakan
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RiwayatUjian;
