import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../Context/AuthContext";

function Menu() {
  const { auth, logout } = useAuth(); // Mendapatkan auth dan logout dari AuthContext
  const location = useLocation();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(""); // Default to main page
  const [isStudent, setIsStudent] = useState(false);

  const menuList = [
    { link: "/", label: "Halaman Utama", icon: "fas fa-home text-primary" },
    {
      link: "/bank-soal",
      label: "Bank Soal",
      icon: "fas fa-book text-success"
    },
    {
      link: "/data-peserta",
      label: "Data Peserta",
      icon: "fas fa-users text-primary"
    },
    {
      link: "/daftar-ujian",
      label: "Daftar Ujian",
      icon: "fas fa-clipboard-list text-warning"
    },
    {
      link: "/monitor-ujian",
      label: "Monitor Ujian",
      icon: "fas fa-eye text-info"
    },
    {
      link: "/riwayat-ujian",
      label: "Riwayat Ujian",
      icon: "fas fa-history text-danger"
    },
    {
      link: "/riwayat-peserta",
      label: "Log Aktivitas",
      icon: "fas fa-user-clock text-success"
    },
    {
      link: "/keluar",
      label: "Keluar",
      icon: "fas fa-sign-out-alt text-danger"
    }
  ];

  useEffect(() => {
    // Tentukan tipe user berdasarkan state auth dari context
    setIsStudent(auth.userType === "siswa");
  }, [auth]);

  const handleMenuClick = (path) => {
    setActiveMenu(path);
    if (path === "/keluar") {
      showLogoutConfirmation();
    } else {
      navigate(path);
    }
  };

  const showLogoutConfirmation = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Anda yakin ingin logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, logout",
      cancelButtonText: "Batal"
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  return (
    <div className="col-md-10 col-sm-12 top-section mt-3">
      <div className="top-section-inner">
        <div className="wrapper show-section">
          <section className="steps-top">
            <div className="steps-inner-top">
              <div className="main-heading text-center">
                <span className="highlighted">Menu</span>
              </div>
              <hr />
              <div id="step1" className="d-flex justify-content-center">
                <div className="d-flex flex-wrap justify-content-center">
                  {menuList.map(
                    (menu) =>
                      (!isStudent || menu.link === "/keluar") && ( // Siswa hanya melihat menu "Keluar"
                        <div
                          key={menu.link}
                          className={`menu-item p-3 text-center ${
                            location.pathname === menu.link ? "active" : ""
                          }`}
                          onClick={() => handleMenuClick(menu.link)}
                          style={{ minWidth: "80px", cursor: "pointer" }}
                        >
                          <i
                            className={`${menu.icon} fa-2x d-md-inline d-none`}
                          />
                          <i
                            className={`${menu.icon} fa-1x d-md-none d-inline`}
                          />
                          <label className="d-block mt-2">{menu.label}</label>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Menu;
