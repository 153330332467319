import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function DetailSoal() {
  const { id } = useParams(); // ID bank soal dari URL
  const [bankSoal, setBankSoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBankSoal = async () => {
      try {
        const response = await axios.get(`/banksoal/daftar/${id}`);
        setBankSoal(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Terjadi kesalahan: ${err.message}`
        });
        setLoading(false);
      }
    };

    fetchBankSoal();
  }, [id]);

  const handleDelete = async (soalId) => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Soal ini akan dihapus secara permanen!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/soal/${soalId}`);
          Swal.fire("Berhasil!", "Soal berhasil dihapus.", "success");
          setBankSoal((prev) => ({
            ...prev,
            soal: prev.soal.filter((soal) => soal.id !== soalId)
          }));
        } catch (err) {
          Swal.fire("Error", "Gagal menghapus soal.", "error");
        }
      }
    });
  };

const handleUploadTemplate = async () => {
  if (!file) {
    Swal.fire({
      icon: "warning",
      title: "Peringatan",
      text: "Silakan pilih file template terlebih dahulu",
    });
    return;
  }

  const formData = new FormData();
  formData.append("file_soal", file);
  formData.append("bank_soal_id", id);

  Swal.fire({
    title: "Mengunggah file...",
    text: "Proses upload sedang berlangsung, harap tunggu.",
    didOpen: () => Swal.showLoading(),
    allowOutsideClick: false,
  });

  try {
    const response = await axios.post(`/soal/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    Swal.fire({
      icon: "success",
      title: "Berhasil",
      text: response.data.message || "File berhasil diunggah.",
    });

    setFile(null); // Reset file setelah sukses
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Gagal",
      text: err.response?.data?.message || "Gagal mengunggah file.",
    });
  }
};


const handleSelectFile = () => {
  Swal.fire({
    title: "Pilih file template",
    input: "file",
    inputAttributes: {
      accept: ".docx,.doc,.pdf", // File yang diperbolehkan
      multiple: false
    },
    showCancelButton: true,
    confirmButtonText: "Pilih",
    cancelButtonText: "Batal",
    preConfirm: () => {
      const fileInput = Swal.getInput(); // Mendapatkan elemen input
      const selectedFile = fileInput.files[0]; // Ambil file yang dipilih

      if (!selectedFile) {
        Swal.showValidationMessage("Silakan pilih file terlebih dahulu.");
        return false;
      }

      const fileName = selectedFile.name.toLowerCase();
      const fileSize = selectedFile.size;

      // Validasi berdasarkan ekstensi file
      if (
        !fileName.endsWith(".docx") &&
        !fileName.endsWith(".doc") &&
        !fileName.endsWith(".pdf")
      ) {
        Swal.showValidationMessage(
          "Hanya file .docx, .doc, atau .pdf yang diperbolehkan."
        );
        return false;
      } else if (fileSize > 10 * 1024 * 1024) {
        Swal.showValidationMessage("Ukuran file tidak boleh lebih dari 10MB.");
        return false;
      }

      setFile(selectedFile); // Simpan file yang dipilih ke state
      return true;
    }
  }).then((result) => {
    if (result.isConfirmed) {
      handleUploadTemplate(); // Lanjutkan upload setelah file dipilih
    }
  });
};




const handleUpdate = (id, soalId) => {
    navigate(`/bank-soal/edit/${id}/${soalId}`);
  };

  if (loading) {
    return (
      <div className="container text-center my-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p>Memuat data bank soal...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center my-5">
        <div className="alert alert-danger">Terjadi kesalahan: {error}</div>
        <button
          className="btn btn-outline-dark mb-3"
          onClick={() => navigate(`/bank-soal`)}
        >
          <i className="fa-solid fa-arrow-left-long fa-fade me-2" />
          Kembali
        </button>
      </div>
    );
  }

  if (!bankSoal?.soal?.length) {
    return (
      <div className="text-center">
        <div className="alert alert-warning">
          Tidak ada soal dalam bank soal ini. Silakan tambahkan soal.
        </div>
        <button
          className="btn btn-outline-dark me-3"
          onClick={() => navigate(`/bank-soal/`)}
        >
          <i className="fa-solid fa-arrow-left-long fa-fade me-2" />
          Kembali
        </button>
        <button
          className="btn btn-success"
          onClick={() => navigate(`/bank-soal/tambah/${id}`)}
        >
          <i className="fa-solid fa-plus fa-fade me-2" />
          Tambah Soal
        </button>
        <button className="btn btn-primary ms-3" disabled onClick={handleSelectFile}>
          {uploading ? "Mengunggah..." : "Pilih dan Unggah Template"}
        </button>
        {fileError && (
          <div className="alert alert-danger mt-2">{fileError}</div>
        )}
      </div>
    );
  }

  return (
    <div className="container my-5">
      <div className="text-center">
        <div className="main-heading text-center mb-1">
          <span className="highlighted">Detail {bankSoal.nama_soal}</span>
        </div>
        <h4 className="mb-5">
          Mata Pelajaran: {bankSoal.mata_pelajaran}
          <br />
          Guru Pelajaran : {bankSoal.pembuat}
        </h4>
        {/* Tombol Tambah Soal */}
        <button
          className="btn btn-success mb-3"
          onClick={() => navigate(`/bank-soal/tambah/${id}`)}
        >
          <i className="fa-solid fa-plus fa-fade me-2" />
          Tambah Soal
        </button>
      </div>
      {/* Tombol Kembali */}
      <button
        className="btn btn-outline-dark mb-3"
        onClick={() => navigate(`/bank-soal`)}
      >
        <i className="fa-solid fa-arrow-left-long fa-fade me-2" />
        Kembali
      </button>

      {bankSoal.soal.map((soal, index) => (
        <section key={soal.id} className="steps step-2 step-custom mb-4">
          <div className="steps-inner">
            <div className="text-center">
              <div className="main-heading2">
                <span className="highlighted">SOAL {index + 1}</span>
              </div>
              <span>Tipe Soal: {soal.tipe_soal}</span>
              {soal.tipe_soal === "Pilihan Ganda" && (
                <p>Bobot Soal: {soal.bobot || "Tidak ditentukan"}</p>
              )}
            </div>
            <hr />
           {soal.tipe_soal === "Pilihan Ganda" && (
  <div className="mt-2" style={{ fontSize: "18px" }}>
    <span dangerouslySetInnerHTML={{ __html: soal.teks_soal }} />
  </div>
)}


            {soal.tipe_soal === "Pilihan Ganda" &&
              soal.pilihan_soal.length > 0 && (
                <div id="detailsoal" className="lightSpeedIn">
                  <b className="mb-3">Pilihan:</b>
                  {soal.pilihan_soal.map((pilihan, idx) => (
                    <div
                      key={pilihan.id}
                      className={
                        pilihan.is_correct
                          ? "text_input3 focus"
                          : "text_input2 focus mt-2"
                      }
                      style={{
                        color: pilihan.is_correct ? "green" : "black",
                        fontWeight: pilihan.is_correct ? "bold" : "normal"
                      }}
                    >
                      <label
                        style={{
                          color: pilihan.is_correct ? "green" : "black",
                          fontWeight: pilihan.is_correct ? "bold" : "normal"
                        }}
                      >
                        {String.fromCharCode(65 + idx)}.
                      </label>
                      <strong
                        className="mt-4 ms-3"
                        dangerouslySetInnerHTML={{
                          __html: pilihan.teks_pilihan
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}

            {soal.tipe_soal === "Benar-Salah" && (
              <div className="table-responsive mt-4">
                <table className="table table-bordered mx-auto text-center" style={{ fontSize: "18px" }}>
                  <thead>
                    <tr>
                      <th>Teks Soal</th>
                      <th>Jawaban</th>
                      <th>Bobot</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span dangerouslySetInnerHTML={{ __html: soal.teks_soal }} /></td>
                      <td>{soal.kunci_jawaban}</td>
                      <td>{soal.bobot || "Tidak ditentukan"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {soal.tipe_soal === "Penjodohan" &&
              soal.penjodohan_soal.length > 0 && (
                <div className="table-responsive mt-4">
                  <table className="table table-bordered text-center mx-auto" style={{ fontSize: "18px" }}>
                    <thead>
                      <tr>
                        <th>Pernyataan</th>
                        <th>Pasangan</th>
                        <th>Bobot</th>
                      </tr>
                    </thead>
                    <tbody>
                      {soal.penjodohan_soal.map((pair) => (
                        <tr key={pair.id}>
                          <td><span dangerouslySetInnerHTML={{ __html: pair.pernyataan }} /></td>
                          <td><span dangerouslySetInnerHTML={{ __html: pair.pasangan }} /></td>
                          <td>{pair.bobot || "Tidak ditentukan"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

            <div className="mt-3 text-end">
              <button
  className="btn btn-warning me-2"
  onClick={() => handleUpdate(id, soal.id)} // Pass `true` for isEditMode
>
  Edit Soal
</button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(soal.id)}
              >
                Hapus Soal
              </button>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default DetailSoal;
