import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

function BankSoal() {
  const [pegawai, setPegawai] = useState(null);
  const [pelajaranList, setPelajaranList] = useState([]);
  const [selectedPelajaran, setSelectedPelajaran] = useState("Semua Pelajaran");
  const [searchQuery, setSearchQuery] = useState("");
  const [soalData, setSoalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchPegawai();
  }, []);

  useEffect(() => {
    if (pegawai) {
      fetchPelajaran();
      fetchData();
    }
  }, [pegawai]);

  const fetchPegawai = async () => {
    try {
      const response = await axios.get("/pegawai");
      setPegawai(response.data);
    } catch {
      Swal.fire("Gagal", "Gagal memuat data pegawai", "error");
    }
  };

  const fetchPelajaran = async () => {
    try {
      const response = await axios.get("/smam/tenaga-pendidik");
      const mataPelajaranWithGuru = response.data.map((item) => ({
        mata_pelajaran: item.mata_pelajaran,
        nama_guru: item.nama
      }));

      // Filter mata pelajaran jika bukan kode pegawai tertentu
      if (
        !["SMAM-OPERATOR", "SMAM-KEPALA", "SMAM-14"].includes(
          pegawai.kode_pegawai
        )
      ) {
        const userPelajaran = mataPelajaranWithGuru.filter(
          (item) => item.kode_pegawai === pegawai.kode_pegawai
        );
        setPelajaranList(userPelajaran);
      } else {
        const uniquePelajaran = [
          ...new Map(
            mataPelajaranWithGuru.map((item) => [item.mata_pelajaran, item])
          ).values()
        ];
        setPelajaranList(uniquePelajaran);
      }
    } catch {
      Swal.fire("Gagal", "Gagal memuat data pelajaran", "error");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/banksoal");

      // Filter soal jika bukan kode pegawai tertentu
      if (
        !["SMAM-OPERATOR", "SMAM-KEPALA", "SMAM-14"].includes(
          pegawai.kode_pegawai
        )
      ) {
        const filteredSoal = response.data.filter(
          (item) =>
            item.mata_pelajaran === pegawai.tenaga_pendidik.mata_pelajaran
        );
        setSoalData(filteredSoal);
      } else {
        setSoalData(response.data);
      }
    } catch {
      Swal.fire("Gagal", "Gagal memuat data soal", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBankSoal = () => {
    Swal.fire({
      title: "Buat Bank Soal Baru",
      html: `
        <input type="text" id="nama_soal" class="swal2-input" placeholder="Keterangan Soal">
        ${
          ["SMAM-OPERATOR", "SMAM-KEPALA", "SMAM-14"].includes(
            pegawai.kode_pegawai
          )
            ? `<select id="mata_pelajaran" class="swal2-input mt-3 mb-3">
                <option value="">Pilih Mata Pelajaran</option>
                ${pelajaranList
                  .map(
                    (pelajaran) =>
                      `<option value="${pelajaran.mata_pelajaran}" data-guru="${pelajaran.nama_guru}">
                        ${pelajaran.mata_pelajaran} 
                      </option>`
                  )
                  .join("")}
              </select>`
            : ""
        }
      `,
      confirmButtonText: "Simpan",
      showCancelButton: true,
      cancelButtonText: "Batal",
      preConfirm: () => {
        const namaSoal = Swal.getPopup().querySelector("#nama_soal").value;
        const mataPelajaranElement =
          Swal.getPopup().querySelector("#mata_pelajaran");
        const mataPelajaran = mataPelajaranElement
          ? mataPelajaranElement.value
          : pegawai.tenaga_pendidik.mata_pelajaran;

        const pembuat = mataPelajaranElement
          ? mataPelajaranElement.options[mataPelajaranElement.selectedIndex]
              .dataset.guru
          : pegawai.tenaga_pendidik.nama;

        if (!namaSoal || !mataPelajaran) {
          Swal.showValidationMessage(
            "Keterangan soal dan mata pelajaran wajib diisi!"
          );
        }
        return { namaSoal, mataPelajaran, pembuat };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { namaSoal, mataPelajaran, pembuat } = result.value;
        createBankSoal({
          nama_soal: namaSoal,
          mata_pelajaran: mataPelajaran,
          pembuat: pembuat
        });
      }
    });
  };

  const createBankSoal = async (data) => {
    try {
      await axios.post("/banksoal", data);
      Swal.fire("Berhasil", "Bank soal berhasil dibuat", "success");
      fetchData();
    } catch {
      Swal.fire("Gagal", "Gagal membuat bank soal", "error");
    }
  };

  const handleEditBankSoal = (soal) => {
    Swal.fire({
      title: "Edit Bank Soal",
      html: `
        <input type="text" id="edit_nama_soal" class="swal2-input" placeholder="Keterangan Soal" value="${
          soal.nama_soal
        }">
        ${
          ["SMAM-OPERATOR", "SMAM-KEPALA", "SMAM-14"].includes(
            pegawai.kode_pegawai
          )
            ? `<select id="edit_mata_pelajaran" class="swal2-input mt-3">
                <option value="">Pilih Mata Pelajaran</option>
                ${pelajaranList
                  .map(
                    (pelajaran) =>
                      `<option value="${pelajaran.mata_pelajaran}" data-guru="${
                        pelajaran.nama_guru
                      }" ${
                        pelajaran.mata_pelajaran === soal.mata_pelajaran
                          ? "selected"
                          : ""
                      }>
                        ${pelajaran.mata_pelajaran} 
                      </option>`
                  )
                  .join("")}
              </select>`
            : ""
        }
      `,
      confirmButtonText: "Simpan",
      showCancelButton: true,
      cancelButtonText: "Batal",
      preConfirm: () => {
        const namaSoal = Swal.getPopup().querySelector("#edit_nama_soal").value;
        const mataPelajaranElement = Swal.getPopup().querySelector(
          "#edit_mata_pelajaran"
        );
        const mataPelajaran = mataPelajaranElement
          ? mataPelajaranElement.value
          : soal.mata_pelajaran;

        const pembuat = mataPelajaranElement
          ? mataPelajaranElement.options[mataPelajaranElement.selectedIndex]
              .dataset.guru
          : soal.pembuat;

        if (!namaSoal || !mataPelajaran) {
          Swal.showValidationMessage(
            "Nama soal dan mata pelajaran wajib diisi!"
          );
        }

        return { namaSoal, mataPelajaran, pembuat };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { namaSoal, mataPelajaran, pembuat } = result.value;
        updateBankSoal(soal.id, {
          nama_soal: namaSoal,
          mata_pelajaran: mataPelajaran,
          pembuat: pembuat
        });
      }
    });
  };
  
   const handleValidasi = async (id) => {
    try {
      const response = await axios.put(`/soal/validasi/${id}`);
      Swal.fire("Berhasil", "Soal berhasil divalidasi", "success");
      fetchData(); // Menyegarkan data soal setelah validasi
    } catch {
      Swal.fire("Gagal", "Gagal memvalidasi soal", "error");
    }
  };

  const updateBankSoal = async (id, data) => {
    try {
      await axios.put(`/banksoal/${id}`, data);
      Swal.fire("Berhasil", "Bank soal berhasil diperbarui", "success");
      fetchData();
    } catch {
      Swal.fire("Gagal", "Gagal memperbarui bank soal", "error");
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Bank soal akan dihapus secara permanen!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Hapus",
      cancelButtonText: "Batal"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/banksoal/${id}`);
          Swal.fire("Berhasil", "Bank soal berhasil dihapus", "success");
          fetchData();
        } catch {
          Swal.fire("Gagal", "Gagal menghapus bank soal", "error");
        }
      }
    });
  };

  const filteredSoal = soalData.filter(
    (item) =>
      (selectedPelajaran === "Semua Pelajaran" ||
        item.mata_pelajaran === selectedPelajaran) &&
      item.nama_soal?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="col-md-12 col-sm-12">
      <div className="left-section-inner">
        <div className="text-center mb-2">
          <div className="main-heading text-center mb-3">
            <span className="highlighted">Daftar Bank Soal</span>
          </div>
          <p>Kelola bank soal dan tambah soal sesuai kebutuhan Anda.</p>
        </div>
        <div className="container text-center">
          <div className="row g-3 mb-3">
            {["SMAM-OPERATOR", "SMAM-KEPALA", "SMAM-14"].includes(
              pegawai?.kode_pegawai
            ) && (
              <div className="col-sm">
                <select
                  className="form-control"
                  value={selectedPelajaran}
                  onChange={(e) => setSelectedPelajaran(e.target.value)}
                >
                  <option value="Semua Pelajaran">Semua Pelajaran</option>
                  {pelajaranList.map((pelajaran, index) => (
                    <option key={index} value={pelajaran.mata_pelajaran}>
                      {pelajaran.mata_pelajaran}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="col-sm">
              <input
                type="text"
                className="form-control"
                placeholder="Cari soal..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="col-sm">
              <button
                className="btn btn-primary"
                onClick={handleCreateBankSoal}
              >
                Buat Bank Soal
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-bordered text-center">
              <thead className="thead-dark">
                <tr>
                  <th>Keterangan Soal</th>
                  <th>Mata Pelajaran</th>
                  <th>Guru</th>
                  <th>Status</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {filteredSoal.length > 0 ? (
                  filteredSoal.map((item) => (
                    <tr key={item.id}>
                      <td>{item.nama_soal}</td>
                      <td>{item.mata_pelajaran}</td>
                      <td>{item.pembuat}</td>
                      {item.status_validasi !== "Tervalidasi" ? (
             <td>{item.status_validasi}</td>
            ) : (
            <td className="bg-success-subtle">
  <b>{item.status_validasi}</b>
</td>
            )}
                     
                     
                      <td>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Bank Soal Actions"
                        > 
                        {item.status_validasi !== "Tervalidasi" ? (
              <button
                className="btn btn-success btn-sm me-2"
                onClick={() => handleValidasi(item.id)}
              >
                Validasi
              </button>
            ) : (
             ""
            )}
                          <button
                            className="btn btn-info btn-sm me-2"
                            onClick={() =>
                              navigate(`/bank-soal/detail/${item.id}`)
                            }
                          >
                            Lihat
                          </button>
                          <button
                            className="btn btn-warning btn-sm me-2"
                            onClick={() => handleEditBankSoal(item)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(item.id)}
                          >
                            Hapus
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      Tidak ada bank soal ditemukan.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default BankSoal;
