import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext"; // Import hook AuthContext
import Swal from "sweetalert2";

function Login() {
  const [credential, setCredential] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State untuk mengatur visibilitas password
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { auth, login } = useAuth(); // Ambil state auth dan fungsi login dari context

  // Cek jika pengguna sudah terautentikasi
if (auth.isAuthenticated) {
    navigate("/"); // Directly navigate to home if authenticated
    return null; // Make sure to return null here
  }

const handleLogin = async (e) => {
  e.preventDefault();

  try {
    await login(credential, password); // Login attempt

    // No need for a setTimeout for navigation; it's handled in the login function
  } catch (err) {
    const errorMessage =
      err.response?.status === 401
        ? "User atau password salah."
        : err.response?.status === 403
        ? "User ini sudah login di perangkat lain."
        : "Terjadi kesalahan. Coba lagi nanti.";

    setError(errorMessage);
    Swal.fire({
      title: "Error",
      text: errorMessage,
      icon: "error"
    });
  }
};

  return (
    <div className="col-md-6 col-sm-12 right-section">
      <div className="right-section-inner">
        <div className="wrapper show-section">
          <section className="steps step-2 step-custom">
            <div className="steps-inner">
              <div className="main-heading text-center">
                <span className="highlighted">Login</span>
              </div>
              <h6 className="text-center me-3 mt-2">
                Silahkan isi form untuk masuk
              </h6>
              {/* form login */}
              <div id="step2" className="lightSpeedIn scroll-form">
                <form onSubmit={handleLogin} className="form-inner">
                  {error && <p className="text-danger text-center">{error}</p>}

                  <div className="block">
                    <div className="text_input focus">
                      <label className="hide">
                        <i className="fa-solid fa-envelope" /> Username
                      </label>
                      <input
                        type="text"
                        name="credential"
                        placeholder="Masukkan User"
                        value={credential}
                        onChange={(e) => setCredential(e.target.value)}
                        required
                      />
                    </div>

                    <div className="text_input focus">
                      <label className="hide">
                        <i className="fa-solid fa-lock" /> Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Masukkan Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                       <a
                       className="mt-4 me-2 text-dark"
                          onClick={() => setShowPassword(!showPassword)} // Toggle state showPassword
                        >
                          <i className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`} />
                        </a>
                    </div>
                  </div>

                  {/* Tombol submit */}
                  <div className="next-prev pop">
                    <button id="step2btn" type="submit" className="next">
                      MASUK
                      <i className="fa-solid fa-arrow-right" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Login;
