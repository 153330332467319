import React from "react";

function AuthDesc() {
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 10) return "Selamat Pagi";
    if (hour < 14) return "Selamat Siang";
    if (hour < 18) return "Selamat Sore";
    return "Selamat Malam";
  };

  const greeting = getGreeting();
  return (
    <div className="col-md-6 col-sm-12 right-section d-md-block d-none">
      <div className="right-section-inner">
        <div className="wrapper show-section">
          <section className="steps step-1">
            <div className="steps-inner">
              <div className="main-heading text-center">
                <span className="highlighted">{greeting}!</span>
              </div>
              <h4 className="highlighted text-center">
                Selamat Datang di Portal Ujian SMAM
              </h4>
              <hr />
              <div id="step1" className="d-flex justify-content-center">
                <div className="left-section-text">
                  Sistem Ujian Online SMAM adalah platform yang dirancang untuk
                  mempermudah siswa dalam mengikuti ujian secara daring.
                  <b> Silakan login untuk mengakses fitur yang tersedia.</b>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AuthDesc;
