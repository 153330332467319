import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import axios from "axios";
import Swal from "sweetalert2";

function PeraturanUjian({ onMengerti }) {
  const { auth } = useAuth();
  const [userData, setUserData] = useState({});
  const fetchUserData = async () => {
    try {
      if (!auth.isAuthenticated || !auth.token) return;
      const endpoint = auth.userType === "siswa" ? "/siswa" : "/pegawai";
      const response = await axios.get(endpoint, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });

      const user = response.data.user;
      setUserData({
        name:
          auth.userType === "siswa"
            ? user.peserta_didik?.nama || "Nama tidak tersedia"
            : user.nama || "Nama tidak tersedia",
        nisn: user.peserta_didik?.nisn || "NISN tidak tersedia",
        kelas: user.peserta_didik?.kelas || "Kelas tidak tersedia",
        jabatan: user.jabatan || "Jabatan tidak tersedia",
        foto: user.peserta_didik?.foto || user.foto || ""
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      Swal.fire("Error", "Gagal mengambil data pengguna.", "error");
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated && auth.token) {
      fetchUserData();
    }
  }, [auth.isAuthenticated, auth.token]);
  return (
    <main className="show-section overflow-hidden">
      <div className="container">
        <div className="start-wrapper">
          {/* start section */}
          <section className="start">
            {/* image */}
            <div className="start-img">
              <img
                src="https://ujian.smamutas.sch.id/assets/images/logo.png"
                alt="start1"
              />
            </div>
            {/* content */}
            <article className="start-text">
              <h3>Hey, {userData.name}!</h3>
              <p>
                Selamat datang di
                <b className="text-primary"> MuTAS - ExamPro</b>.
                <br />
                Berikut adalah beberapa peraturan ujian yang perlu diperhatikan:
              </p>
              <p className="text-danger">
                1. Pastikan koneksi internet stabil selama ujian.
                <br />
                2. Tidak diperkenankan menggunakan alat bantu atau menyontek.
                <br />
                3. Ujian hanya dapat dikerjakan dalam waktu yang telah
                ditentukan.
                <br />
                4.
                <b>
                  Jangan meninggalkan atau keluar dari halaman ujian, karna soal
                  akan otomatis terkunci.
                </b>
                <br />
                5.
                <b>
                  Jangan berpindah tab atau menutupi layar ujian, karan soal
                  akan otomatis terkunci.
                </b>
                <br />
                6. Jika terjadi masalah teknis, segera laporkan kepada pengawas
                ujian.
                <br />
                7. Setelah mengerjakan, pastikan untuk mengirimkan jawaban
                dengan benar.
              </p>
              <p>
                Setelah membaca peraturan ini, klik tombol "Mengerti" untuk
                melanjutkan ujian.
              </p>
            </article>

            <button
              className="next delay-1000ms start-btn"
              onClick={onMengerti}
            >
              Mengerti
            </button>
          </section>
        </div>
      </div>
    </main>
  );
}

export default PeraturanUjian;
