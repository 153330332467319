import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Fungsi untuk menghitung sisa waktu berdasarkan tanggal ujian, waktu ujian, dan durasi ujian
const calculateTimeLeft = (tanggalUjian, waktuUjian, durasiUjian) => {
  const currentDate = new Date();
  
  // Mengonversi tanggal dan waktu ujian ke dalam objek Date
  const [hours, minutes, seconds] = waktuUjian.split(":").map(Number);
  const examDate = new Date(`${tanggalUjian}T${waktuUjian}`);

  // Menambahkan durasi ujian dalam menit ke waktu ujian
  examDate.setMinutes(examDate.getMinutes() + durasiUjian);

  // Menghitung sisa waktu
  const timeDiff = examDate - currentDate;

 if (timeDiff <= 0) {
    return { minutes: 0, seconds: 0, finished: true };
  }

  // Menghitung menit dan detik
  const minutesLeft = Math.floor(timeDiff / 60000);
  const secondsLeft = Math.floor((timeDiff % 60000) / 1000);

 return { minutes: minutesLeft, seconds: secondsLeft, finished: false };
};

function Content() {
  const [daftarUjian, setDaftarUjian] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState({});
  const navigate = useNavigate();

  // Fetch daftar ujian awal
  const fetchData = async () => {
    try {
      const response = await axios.get("/daftarujian");
      const ujianSedangBerlangsung = response.data.daftar_ujian.filter(
        (ujian) => ujian.status_ujian === "Sedang Berlangsung" // Filter status ujian
      );
      setDaftarUjian(ujianSedangBerlangsung); // Menyimpan ujian yang sedang berlangsung
      setLoading(false);
    } catch (error) {
      setError("Gagal memuat data ujian.");
      setLoading(false);
    }
  };
  
  // Fungsi untuk mengupdate countdown setelah komponen dirender
  useEffect(() => {
    if (daftarUjian.length > 0) {
      const ujian = daftarUjian[0];

      // Fungsi untuk mengupdate waktu
      const updateCountdown = () => {
        const timeLeft = calculateTimeLeft(ujian.tanggal_ujian, ujian.waktu_ujian, ujian.durasi_ujian);
        setCountdown(timeLeft); // Update countdown

        if (!timeLeft.finished) {
          // Jika waktu belum habis, panggil updateCountdown setelah 1 detik
          setTimeout(updateCountdown, 1000);
        }
      };

      // Memulai countdown setelah komponen dirender
      updateCountdown();
    }
  }, [daftarUjian]);


  // Menampilkan sisa waktu dalam format countdown
  const renderCountdown = () => {
    if (countdown.finished) {
      return <span>Waktu ujian telah habis</span>;
    }
    return (
      <span>
        {countdown.minutes} menit {countdown.seconds} detik
      </span>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="col-md-12 col-sm-12">
      <div className="left-section-inner">
        <div className="text-center mb-2">
          <div className="main-heading text-center mb-3">
            <span className="highlighted">Monitor Ujian</span>
          </div>
          <p>
            Pantau dan kelola semua aktivitas ujian secara real-time, mulai dari
            jadwal hingga hasil peserta. Pastikan pelaksanaan ujian berjalan
            lancar tanpa hambatan.
          </p>
        </div>
        <div className="table-responsive">
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : error ? (
            <div className="alert alert-danger text-center">
              <b className="text-center text-danger">{error}</b>
            </div>
          ) : daftarUjian.length > 0 ? (
            <table className="table table-bordered text-center">
              <thead className="thead-dark">
                <tr>
                  <th>Kelas</th>
                  <th>Pelajaran</th>
                  <th>Status Ujian</th>
                  <th>Sisa Waktu</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {daftarUjian.map((ujian, index) => {
                  const sisaWaktu = calculateTimeLeft(ujian.tanggal_ujian, ujian.waktu_ujian, ujian.durasi_ujian);
                  return (
                    <tr key={index}>
                      <td>{ujian.kelas.join(", ")}</td> {/* Menampilkan kelas */}
                      <td>{ujian.bank_soal.mata_pelajaran}</td> {/* Menampilkan mata pelajaran */}
                      <td>{ujian.status_ujian}</td>
                     <td>{renderCountdown()}</td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => navigate(`/monitor-ujian/detail/${ujian.token_ujian}`)}
                        >
                          Monitor
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="text-center">
              Tidak ada ujian yang sedang berlangsung.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Content;
