import React, { useEffect, useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Waktu from "./Components/Waktu";
import Logo from "./Components/Logo";
import Menu from "./Components/Menu";
import JadwalUjian from "../Siswa/Components/JadwalUjian";
import Loading from "./Components/Loading";

const MainLayouts = ({ children }) => {
  const { auth, isLoading } = useAuth(); // Access auth context
  const [userType, setUserType] = useState(auth.userType); // Get userType from context

  // Update userType when it changes
  useEffect(() => {
    if (auth.userType) {
      setUserType(auth.userType);
    }
  }, [auth.userType]);

   if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="wrapper">
        <Waktu />
        <Logo />
        <div className="overflow-hidden">
          <div className="container mt-lg-5 mt-4">
            <div className="row">
              {children}
              <div className="d-md-none d-sm-block">
                <hr />
              </div>
              {userType === "siswa" ? <JadwalUjian /> : <Menu />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayouts;
