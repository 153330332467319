import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import PilihanGanda from "./Components/PilihanGanda";
import BenarSalah from "./Components/BenarSalah";
import Menjodohkan from "./Components/Menjodohkan";

function ControlSoal() {
  const [menu, setMenu] = useState("pilihan-ganda"); // Menu default
  const [bankSoal, setBankSoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { id: soalId, id } = useParams(); // `soalId` hanya ada di mode edit
  const location = useLocation();
  const isEditMode = location.pathname.includes("edit");

  // Fetch data bank soal
  useEffect(() => {
    const fetchBankSoal = async () => {
      try {
        const response = await axios.get(`/banksoal/${soalId}`);
        setBankSoal(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBankSoal();
  }, [id]);

  if (loading) {
    return (
      <div className="container text-center my-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p>Memuat data bank soal...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center my-5">
        <div className="alert alert-danger">Terjadi kesalahan: {error}</div>
        <button
          className="btn btn-outline-dark mb-3"
          onClick={() => navigate(`/bank-soal/detail/${id}`)}
        >
          <i className="fa-solid fa-arrow-left-long fa-fade me-2" />
          Kembali
        </button>
      </div>
    );
  }

  return (
    <div className="container mb-4">
      <div className="main-heading text-center mb-3">
        <span className="highlighted">
          {isEditMode ? "Edit Soal" : "Tambah Soal"}
        </span>
      </div>

      <div className="text-center mb-4">
        <h5>{bankSoal.nama_soal}</h5>
        <h6 className="mb-4">
          {bankSoal.pembuat} - {bankSoal.mata_pelajaran}
        </h6>
      </div>

      <button
        className="btn btn-outline-dark mb-3"
        onClick={() => navigate(`/bank-soal/detail/${id}`)}
      >
        <i className="fa-solid fa-arrow-left-long fa-fade me-2" />
        Kembali
      </button>

      <div className="d-flex justify-content-center mb-4">
        <button
          className={`btn me-2 ${
            menu === "pilihan-ganda" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setMenu("pilihan-ganda")}
        >
          Pilihan Ganda
        </button>
        <button
          className={`btn me-2 ${
            menu === "benar-salah" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setMenu("benar-salah")}
        >
          Benar / Salah
        </button>
        <button
          className={`btn ${
            menu === "menjodohkan" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setMenu("menjodohkan")}
        >
          Penjodohan
        </button>
      </div>

      {menu === "pilihan-ganda" && <PilihanGanda isEditMode={isEditMode} />}
      {menu === "benar-salah" && (
        <BenarSalah isEditMode={isEditMode} bankSoalId={bankSoal?.id} />
      )}
      {menu === "menjodohkan" && (
        <Menjodohkan isEditMode={isEditMode} bankSoalId={bankSoal?.id} />
      )}
    </div>
  );
}

export default ControlSoal;
