import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function Content() {
  const [peserta, setPeserta] = useState([]);
  const [filteredPeserta, setFilteredPeserta] = useState([]);
  const [kelasList, setKelasList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedKelas, setSelectedKelas] = useState("Semua");
  const [filterLogin, setFilterLogin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    fetchPeserta();
    fetchKelas();
  }, []);

  const fetchPeserta = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/peserta");
      setPeserta(response.data);
      setFilteredPeserta(response.data);
      setLoading(false);
    } catch (error) {
      Swal.fire("Error", "Gagal mengambil data peserta!", "error");
    }
  };

  const fetchKelas = async () => {
    try {
      const response = await axios.get("/daftar-kelas");
      setKelasList(["Semua", ...response.data.daftar_kelas]);
    } catch (error) {
      Swal.fire("Error", "Gagal mengambil daftar kelas!", "error");
    }
  };

  const handleResetLogin = async (nisn) => {
    try {
      await axios.post(`/peserta/reset-login/${nisn}`);
      Swal.fire("Sukses", "Status login berhasil direset!", "success");
      fetchPeserta();
    } catch (error) {
      Swal.fire("Error", "Gagal mereset status login!", "error");
    }
  };
 

  const handleSearch = (term) => {
    setSearchTerm(term);
    applyFilters(term, selectedKelas, filterLogin);
  };

  const handleKelasChange = (kelas) => {
    setSelectedKelas(kelas);
    applyFilters(searchTerm, kelas, filterLogin);
  };

const handleFilterLogin = (isChecked) => {
  setFilterLogin(isChecked);
  applyFilters(searchTerm, selectedKelas, isChecked);
};


  const applyFilters = (term, kelas, login) => {
    let data = [...peserta];

    if (term) {
      data = data.filter(
        (item) =>
          item.nama.toLowerCase().includes(term.toLowerCase()) ||
          item.nisn.toString().includes(term)
      );
    }

    if (kelas !== "Semua") {
      data = data.filter((item) => item.kelas === kelas);
    }

    if (login) {
      data = data.filter((item) => item.cek_akses?.is_login_ujian === 1);
    }

    setFilteredPeserta(data);
    setCurrentPage(1);
  };

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredPeserta.slice(startIndex, startIndex + itemsPerPage);
  };

  const totalPages = Math.ceil(filteredPeserta.length / itemsPerPage);

  return (
    <div className="col-md- col-sm-12">
      <div className="left-section-inner">
        <div className="text-center mb-2">
          <div className="main-heading text-center mb-3">
            <span className="highlighted">Data Peserta</span>
          </div>
          <p>Kelola data dan akses peserta ujian.</p>
        </div>
        <div className="row justify-content-center align-items-center mb-3">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Cari Nama / NISN"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="col">
            <select
              className="form-select"
              value={selectedKelas}
              onChange={(e) => handleKelasChange(e.target.value)}
            >
              {kelasList.map((kelas, index) => (
                <option key={index} value={kelas}>
                  {kelas}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <label>
              <input
                type="checkbox"
                className="me-2"
                checked={filterLogin}
                onChange={(e) => handleFilterLogin(e.target.checked)}
              />
              Sedang Login
            </label>
            
          </div>
          
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr className="text-center">
              
              <th>Kelas</th>
              <th>Nama</th>
              <th>Login</th>
              
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center">
                  Memuat data...
                </td>
              </tr>
            ) : filteredPeserta.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">
                  Tidak ada data.
                </td>
              </tr>
            ) : (
              paginateData().map((item, index) => (
                <tr key={item.id} className="text-center">
                 
                  <td>{item.kelas}</td>
                  <td>{item.nama}</td>

                  <td
                    className={
                      item.cek_akses?.is_login_ujian === 1
                        ? "table-success"
                        : ""
                    }
                  >
                    {item.cek_akses?.is_login_ujian === 1
                      ? "Sedang Login"
                      : "-"}
                  </td>
                 
                  <td>
                    <button
                      className={`btn btn-sm mx-1 mb-1 ${
                        item.cek_akses?.is_login_ujian === 1
                          ? "btn-danger"
                          : "btn-secondary"
                      }`}
                      onClick={() => handleResetLogin(item.nisn)}
                     disabled={!item.cek_akses || item.cek_akses.is_login_ujian === 0} // Disable jika is_login_ujian === 0
                    >
                      Reset Akses
                    </button>
                    <button
                      className={`btn btn-sm mx-1 mb-1 ${
                        item.cek_akses?.is_ujian === 1
                          ? "btn-warning"
                          : "btn-secondary"
                      }`}
                      onClick={() => handleResetUjian(item.nisn)}
                     disabled={!item.cek_akses || item.cek_akses.is_ujian === 0}// Disable jika is_login_ujian === 0
                    >
                      Reset Ujian
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Sebelumnya
        </button>
        <span className="d-md-block d-none">
          Halaman {currentPage} dari {totalPages}
        </span>
        <button
          className="btn btn-success"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Selanjutnya
        </button>
      </div>
    </div>
  );
}

export default Content;
