import React from "react";
import { useNavigate } from "react-router-dom";
import { useUjian } from "../../Context/UjianContext";

function JadwalUjian() {
  const { ujian, loading, error } = useUjian();
  const navigate = useNavigate();

  return (
    <div className="col-md-8 col-sm-12 center-section">
      <div className="center-section-inner">
        <div className="wrapper show-section">
          <section className="steps step-1">
            <div className="steps-inner">
              <div className="main-heading2 text-center">
                <span className="highlighted">Jadwal Ujian</span>
              </div>
              <hr />
              {loading ? (
                <p className="text-center">Memuat data ujian...</p>
              ) : error ? (
                <p className="text-center text-danger">{error}</p>
              ) : (
                <div id="step1" className="justify-content-center">
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover text-center">
                      <thead className="thead-dark">
                        <tr>
                          <th>No</th>
                          <th>Pelajaran</th>
                          <th>Waktu Mulai</th>
                          <th>Waktu Selesai</th>
                          <th>Status</th>
                          <th>Ruangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ujian.length > 0 ? (
                          ujian.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.pelajaran}</td>
                              <td>
                                {item.tanggal_mulai}
                                <br />
                                Pukul {item.waktu_mulai} WIB
                              </td>
                              <td>
                                {item.tanggal_selesai}
                                <br />
                                Pukul {item.waktu_selesai} WIB
                              </td>
                              <td
                                className={`${
                                  item.status === "Sedang Berlangsung"
                                    ? "text-danger"
                                    : item.status === "Belum Dimulai"
                                    ? "text-warning"
                                    : item.status === "Ujian Selesai"
                                    ? "text-success"
                                    : ""
                                }`}
                                style={{
                                  backgroundColor:
                                    item.status === "Sedang Berlangsung"
                                      ? "rgba(220, 53, 69, 0.2)" // Soft red
                                      : item.status_ujian === "Belum Dimulai"
                                      ? "rgba(255, 193, 7, 0.2)" // Soft yellow
                                      : item.status_ujian === "Ujian Selesai"
                                      ? "rgba(40, 167, 69, 0.2)" // Soft green
                                      : "transparent", // Default background
                                }}
                              >
                                <b>{item.status}</b>
                              </td>
                              <td>
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={() =>
                                    navigate(`/ujian/detail/${item.token_ujian}`)
                                  }
                                  
                                >
                                  Masuk
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              Tidak ada ujian dijadwalkan.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default JadwalUjian;
