import React from "react";

const BenarSalah = ({ selectedOption, handleOptionChange }) => {
  return (
    <div>
      <a
        href="#"
        className={`option-btn ${selectedOption === "Benar" ? "" : ""}`}
        onClick={() => handleOptionChange("Benar")}
      >
        <div
          className={`focus-ujian ${
            selectedOption === "Benar" ? "selected bg-jawaban" : ""
          } form-text-ujian d-flex delay-100ms revealfield`}
        >
          <div className="text-field-icon-ujian revealfield">
            <i className="fa-solid fa-check revealfield" />
          </div>
          <div className="text-field-input-ujian revealfield">
            <input value={"Benar"} disabled />
          </div>
        </div>
      </a>
      <a
        href="#"
        className={`option-btn ${selectedOption === "Salah" ? "" : ""}`}
        onClick={() => handleOptionChange("Salah")}
      >
        <div
          className={`focus-ujian ${
            selectedOption === "Salah" ? "selected bg-jawaban" : ""
          } form-text-ujian d-flex delay-100ms revealfield`}
        >
          <div className="text-field-icon-ujian revealfield">
            <i className="fa-solid fa-times revealfield" />
          </div>
          <div className="text-field-input-ujian revealfield">
            <input value={"Salah"} disabled />
          </div>
        </div>
      </a>
    </div>
  );
};

export default BenarSalah;
