import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import Swal from "sweetalert2";
import Loading from "../Layouts/Components/Loading";

function Content() {
  const { auth, userData, logout, isLoading } = useAuth();
  const [tokenInput, setTokenInput] = useState("");
  const navigate = useNavigate();

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 10) return "Selamat Pagi";
    if (hour < 14) return "Selamat Siang";
    if (hour < 18) return "Selamat Sore";
    return "Selamat Malam";
  };

  const greeting = getGreeting();

  const handleLogout = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Anda yakin ingin logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, logout",
      cancelButtonText: "Batal"
    }).then((result) => {
      if (result.isConfirmed) logout();
    });
  };

  if (isLoading || !userData) {
    return <Loading />;
  }

  return (
    <>
      <div className="main-heading text-center">
        <span className="highlighted">{greeting}!</span>
        <span className="d-md-block d-none">{userData?.name}</span>
      </div>
      <div className="col-md-4 col-sm-12 center-section">
        <div className="center-section-inner">
          <div className="wrapper">
            <section className="steps mt-10">
              <div className="steps-inner">
                <div className="text-center">
                  <img
                    src={
                      userData?.foto
                    }
                    alt="User Avatar"
                    className="rounded-circle"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover"
                    }}
                  />
                  <div className="mt-3">
                    <table className="table text-start">
                      <tbody>
                        <tr>
                          <th>Nama</th>
                          <td>{userData?.name}</td>
                        </tr>
                        <tr>
                          <th>Kelas</th>
                          <td>{userData?.kelas}</td>
                        </tr>
                        <tr>
                          <th>NISN</th>
                          <td>{userData?.nisn}</td>
                        </tr>
                      </tbody>
                    </table>
                    <button
              className="btn btn-outline-danger mt-5"
              onClick={handleLogout}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-sign-out-alt text-danger" /> Keluar Aplikasi
            </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
