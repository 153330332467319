import React, { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

function PilihanGanda({ isEditMode }) {
  const { id, soalId } = useParams();
  const [activeMenu, setActiveMenu] = useState("manual");
  const [questions, setQuestions] = useState([
    {
      questionText: "",
      options: ["", "", "", "", ""],
      answerKey: "",
      bobot: ""
    }
  ]);
  const [arrayData, setArrayData] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const editorRef = useRef([]);

 useEffect(() => {
  if (isEditMode && soalId) {
    const fetchSoal = async () => {
      try {
        const response = await axios.get(`/soal/${soalId}`);
        const { teks_soal, pilihan, kunci_jawaban, bobot } = response.data;
        setQuestions([
          {
            questionText: teks_soal,
            options: pilihan.map((p) => p.isi),
            answerKey: kunci_jawaban,
            bobot,
          },
        ]);
      } catch (error) {
        console.error("Error fetching soal:", error);
      }
    };

    fetchSoal();
  }
}, [isEditMode, soalId]);


  const handleOptionChange = (qIndex, index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[index] = value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        questionText: "",
        options: ["", "", "", "", ""],
        answerKey: "",
        bobot: ""
      }
    ]);
  };

const handleSave = async () => {
  for (let i = 0; i < questions.length; i++) {
    const { questionText, options, answerKey, bobot } = questions[i];
    if (!questionText.trim()) {
      Swal.fire("Error", `Pertanyaan soal ${i + 1} tidak boleh kosong.`, "error");
      return;
    }
    if (questionText.length < 10) {
      Swal.fire("Error", `Pertanyaan soal ${i + 1} harus lebih dari 10 karakter.`, "error");
      return;
    }
    if (options.some((opt) => !opt.trim())) {
      Swal.fire("Error", `Semua pilihan pada soal ${i + 1} harus diisi.`, "error");
      return;
    }
    if (!answerKey.trim()) {
      Swal.fire("Error", `Kunci jawaban soal ${i + 1} harus dipilih.`, "error");
      return;
    }
    const bobotValue = String(bobot).trim();
    if (!bobotValue || isNaN(bobotValue) || Number(bobotValue) <= 0) {
      Swal.fire("Error", `Bobot soal ${i + 1} harus diisi dengan angka valid.`, "error");
      return;
    }
  }

  const payload = questions.map(({ questionText, options, answerKey, bobot }) => ({
    bank_soal_id: id,
    tipe_soal: "Pilihan Ganda",
    teks_soal: questionText,
    pilihan: options.map((opt, index) => ({
      opsi: String.fromCharCode(65 + index),
      isi: opt,
    })),
    kunci_jawaban: answerKey,
    bobot: parseInt(bobot, 10),
  }));

  try {
    setLoading(true);
 console.log("Payload:", JSON.stringify(payload, null, 2)); // Debugging
    if (isEditMode && soalId) {
      // Mode edit: gunakan endpoint PATCH atau PUT
      await axios.put(`/soal/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    Swal.fire("Sukses", "Soal berhasil diperbarui!", "success");
    } else {
      // Mode create: gunakan endpoint POST
      await axios.post("/soal/batch", { soal: payload });
      Swal.fire("Sukses", "Soal berhasil ditambahkan!", "success");
    }

    navigate(`/bank-soal/detail/${id}`);
  } catch (error) {
    Swal.fire(
      "Error",
      error.response?.data?.message || "Terjadi kesalahan.",
      "error"
    );
  } finally {
    setLoading(false);
  }
};

  
  const handleArrayDataSubmit = () => {
    try {
      const parsedData = JSON.parse(arrayData);
      if (!Array.isArray(parsedData)) throw new Error("Data harus berupa array.");

      const newQuestions = parsedData.map((item, index) => {
        if (
          !item.questionText ||
          !Array.isArray(item.options) ||
          item.options.length !== 5 ||
          !item.answerKey ||
          !item.bobot
        ) {
          throw new Error(`Data soal ke-${index + 1} tidak valid.`);
        }
        return {
          questionText: item.questionText,
          options: item.options,
          answerKey: item.answerKey,
          bobot: item.bobot
        };
      });

      setQuestions((prev) => [...prev, ...newQuestions]);
      Swal.fire("Sukses", "Soal berhasil ditambahkan dari array!", "success");
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const handleSaveArray = async () => {
    try {
      setLoading(true);
      const payload = questions.map(({ questionText, options, answerKey, bobot }) => ({
        bank_soal_id: id,
        tipe_soal: "Pilihan Ganda",
        teks_soal: questionText,
        pilihan: options.map((opt, index) => ({
          opsi: String.fromCharCode(65 + index),
          isi: opt
        })),
        kunci_jawaban: answerKey,
        bobot: parseInt(bobot, 10)
      }));

      await axios.post("/soal", { soal: payload });
      Swal.fire("Sukses", "Soal dari array berhasil disimpan!", "success");
      navigate(`/bank-soal/detail/${id}`);
    } catch (error) {
      Swal.fire("Error", "Gagal menyimpan soal dari array.", "error");
    } finally {
      setLoading(false);
    }
  };

  const filePickerCallback = (cb, value, meta) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.addEventListener("change", async (e) => {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("gambar", file);

      try {
        const response = await axios.post("/soal/upload-gambar", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        });

        if (response.data.url) {
          cb(response.data.url, { title: file.name });
        } else {
          Swal.fire("Error", "URL gambar tidak ditemukan.", "error");
        }
      } catch (err) {
        Swal.fire(
          "Error",
          "Gagal mengunggah gambar. Pastikan koneksi stabil.",
          "error"
        );
      }
    });

    input.click();
  };

  return (
    <div className="section">
      <h4>Pilihan Ganda</h4>
      <div className="mb-4">
        <button
          className={`btn ${activeMenu === "manual" ? "btn-primary" : "btn-outline-primary"} me-2`}
          onClick={() => setActiveMenu("manual")}
        >
          Input Manual
        </button>
        <button
          className={`btn ${activeMenu === "array" ? "btn-primary" : "btn-outline-primary"}`}
          onClick={() => setActiveMenu("array")}
        >
          Input Array
        </button>
      </div>
      
      {activeMenu === "manual" && (
      <div>
      {questions.map((question, qIndex) => (
        <div key={qIndex} className="mb-4">
       <hr className="text-primary" style={{ borderWidth: "5px" }} />
          <h5 className="mt-4">Soal {qIndex + 1}</h5>
          <div className="mb-3">
            <label htmlFor="bobot" className="form-label">
              Bobot Soal:
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="Masukkan bobot soal"
              value={question.bobot}
              onChange={(e) => {
                const updatedQuestions = [...questions];
                updatedQuestions[qIndex].bobot = e.target.value;
                setQuestions(updatedQuestions);
              }}
              min="0"
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="questionEditor" className="form-label">
              Pertanyaan:
            </label>
            <Editor
              apiKey="ns96sd0zob0oz7nfplqrmef4zgz7y88x1dmu6bskka15rwmj"
              onInit={(evt, editor) => (editorRef.current[qIndex] = editor)}
              value={question.questionText}
              init={{
                height: 350,
                plugins:
      "image code table advtable lists link autolink charmap preview fullscreen math textpattern align",
    toolbar:
      "undo redo | bold italic underline | numlist bullist | alignleft aligncenter alignright | removeformat | table | link image | math | code preview fullscreen",
                image_title: true,
                automatic_uploads: true,
                file_picker_types: "image",
                file_picker_callback: filePickerCallback,
                content_style:
                  "body { font-family:Times New Roman; font-size:16px }"
              }}
              onEditorChange={(content) => {
                const updatedQuestions = [...questions];
                updatedQuestions[qIndex].questionText = content;
                setQuestions(updatedQuestions);
              }}
            />
          </div>

          {["A", "B", "C", "D", "E"].map((option, index) => (
            <div key={index} className="mb-3 d-flex align-items-start">
              <label
                className="form-label flex-shrink-0 me-3"
                style={{ width: "100px" }}
              >
                <b>Pilihan {option}:</b>
              </label>
              <div className="flex-grow-1">
                <Editor
                  apiKey="ns96sd0zob0oz7nfplqrmef4zgz7y88x1dmu6bskka15rwmj"
                  onInit={(evt, editor) =>
                    (editorRef.current[qIndex + index + 1] = editor)
                  }
                  value={question.options[index]}
                  init={{
                    width: "100%",
                    height: 200,
                    plugins:
      "image code table advtable lists link autolink charmap preview fullscreen math textpattern align",
    toolbar:
      "undo redo | bold italic underline | numlist bullist | alignleft aligncenter alignright | removeformat | table | link image | math | code preview fullscreen",
                    content_style:
                      "body { font-family:Times New Roman; font-size:16px }"
                  }}
                  onEditorChange={(content) =>
                    handleOptionChange(qIndex, index, content)
                  }
                />
              </div>
            </div>
          ))}

          <div className="mb-3">
            <label htmlFor="answerKey" className="form-label">
              Kunci Jawaban:
            </label>
            <select
              id="answerKey"
              className="form-select"
              value={question.answerKey}
              onChange={(e) => {
                const updatedQuestions = [...questions];
                updatedQuestions[qIndex].answerKey = e.target.value;
                setQuestions(updatedQuestions);
              }}
              disabled={loading}
            >
              <option value="">Pilih Jawaban</option>
              {["A", "B", "C", "D", "E"].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      ))}
     
     
      <button
        type="button"
        className="btn btn-success"
        onClick={handleAddQuestion}
        disabled={loading}
      >
        Tambah Soal Baru
      </button> <button
        type="button"
        className="btn btn-primary me-3"
        onClick={handleSave}
        disabled={loading}
      >
        {loading ? "Loading..." : "Simpan Semua"}
      </button></div>
            )}
            
         {activeMenu === "array" && (
  <div style={{ backgroundColor: "#f9f9f9", padding: "20px", borderRadius: "8px" }}>
    <h5 className="mb-3">Format Array</h5>
    <div style={{ position: "relative" }}>
      <textarea
        className="form-control mb-3"
        rows="10"
        disabled
        value={`[
  {
    "questionText": "Apa nama ibukota Indonesia?",
    "options": ["Jakarta", "Bandung", "Surabaya", "Medan", "Makassar"],
    "answerKey": "A",
    "bobot": 5
  },
]`}
        style={{ paddingRight: "100px" }}
      />
      <button
        className="btn btn-outline-secondary"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 10,
        }}
        onClick={() =>
          navigator.clipboard.writeText(`[
  {
    "questionText": "Apa nama ibukota Indonesia?",
    "options": ["Jakarta", "Bandung", "Surabaya", "Medan", "Makassar"],
    "answerKey": "Jakarta",
    "bobot": 5
  },
  {
    "questionText": "Berapa hasil dari 2 + 2?",
    "options": ["1", "2", "3", "4", "5"],
    "answerKey": "4",
    "bobot": 3
  },
  {
    "questionText": "Siapa penulis buku *Laskar Pelangi*?",
    "options": ["Andrea Hirata", "Tere Liye", "Habiburrahman El Shirazy", "Dewi Lestari", "Pramoedya Ananta Toer"],
    "answerKey": "Andrea Hirata",
    "bobot": 4
  }
]`)
        }
      >
        Copy
      </button>
    </div>

    <textarea
      className="form-control mb-3"
      rows="8"
      placeholder='Masukkan data array dalam format: [{"questionText": "Soal 1", "options": ["A", "B", "C", "D", "E"], "answerKey": "A", "bobot": 5}]'
      value={arrayData}
      onChange={(e) => setArrayData(e.target.value)}
    />
    <button className="btn btn-primary me-3" onClick={handleArrayDataSubmit}>
      Tambahkan Soal dari Array
    </button>
    <button
      className="btn btn-success"
      onClick={handleSaveArray}
      disabled={loading}
    >
      {loading ? "Loading..." : "Simpan Soal dari Array"}
    </button>
  </div>
)}

    </div>
  );
}

export default PilihanGanda;
