import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PeraturanUjian from "../Siswa/Components/PeraturanUjian"; // Import PeraturanUjian
import HalamanUjian from "../Siswa/HalamanUjian"; // Import HalamanUjian

function Ujian() {
  const { id } = useParams(); // Mengambil token_ujian dari URL
  const [peraturanDisetujui, setPeraturanDisetujui] = useState(false);
  const navigate = useNavigate();

  const handleMengerti = () => {
    // Setelah siswa mengklik "Mengerti", arahkan ke HalamanUjian
    setPeraturanDisetujui(true);
    navigate(`/ujian/${id}`);
  };

  return (
    <div>
      {peraturanDisetujui ? (
        <HalamanUjian />
      ) : (
        <PeraturanUjian onMengerti={handleMengerti} />
      )}
    </div>
  );
}

export default Ujian;
