import React from "react";
import Waktu from "./Components/Waktu";
import Logo from "./Components/Logo";
import AuthDesc from "./Components/AuthDesc";

const MainLayouts = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <Waktu />

        <Logo />

        <div className="overflow-hidden">
          <div className="container mt-lg-5 mt-0">
            <div className="row">
              <AuthDesc />
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayouts;
