import React, { useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

function BenarSalah({ bankSoalId }) {
  const [questionText, setQuestionText] = useState("");
  const [answerKey, setAnswerKey] = useState("");
  const [bobot, setBobot] = useState("");
  const [loading, setLoading] = useState(false);
  
  // Editor reference
  const editorRef = useRef(null);

  // File picker callback for image upload
  const filePickerCallback = (cb, value, meta) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.addEventListener("change", async (e) => {
      const file = e.target.files[0];

      if (file) {
        const formData = new FormData();
        formData.append("gambar", file);

        try {
          const response = await axios.post("/soal/upload-gambar", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (response.data.url) {
            cb(response.data.url, { title: file.name });
          } else {
            Swal.fire("Error", "URL gambar tidak ditemukan.", "error");
          }
        } catch (err) {
          Swal.fire("Error", "Gagal mengunggah gambar. Pastikan koneksi stabil.", "error");
        }
      }
    });

    input.click();
  };

  // Handle saving question
  const handleSave = async () => {
    // Validasi
    if (!questionText.trim()) {
      Swal.fire("Error", "Pertanyaan tidak boleh kosong.", "error");
      return;
    }
    if (questionText.length < 10) {
      Swal.fire("Error", "Pertanyaan harus lebih dari 10 karakter.", "error");
      return;
    }
    if (!answerKey.trim()) {
      Swal.fire("Error", "Kunci jawaban harus dipilih.", "error");
      return;
    }
    if (!bobot.trim() || isNaN(bobot) || bobot <= 0) {
      Swal.fire("Error", "Bobot soal harus diisi dengan angka valid.", "error");
      return;
    }

    // Data untuk dikirim
    const payload = {
      bank_soal_id: bankSoalId,
      tipe_soal: "Benar-Salah",
      teks_soal: questionText,
      kunci_jawaban: answerKey,
      bobot: parseInt(bobot, 10),
    };

    try {
      setLoading(true);
      await axios.post("/soal", payload); // Kirim data ke backend
      Swal.fire("Sukses", "Soal Benar/Salah berhasil disimpan!", "success");
      // Reset form fields
      setQuestionText("");
      setAnswerKey("");
      setBobot("");
    } catch (error) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Terjadi kesalahan.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h4>Benar / Salah</h4>

      {/* Bobot */}
      <div className="mb-3">
        <label htmlFor="bobot" className="form-label">
          Bobot Soal:
        </label>
        <input
          type="number"
          id="bobot"
          className="form-control"
          value={bobot}
          onChange={(e) => setBobot(e.target.value)}
          placeholder="Masukkan bobot soal"
        />
      </div>

      {/* Pertanyaan */}
      <div className="mb-3">
        <label htmlFor="questionText" className="form-label">
          Pertanyaan:
        </label>
        <Editor
          apiKey="ns96sd0zob0oz7nfplqrmef4zgz7y88x1dmu6bskka15rwmj"
          onInit={(evt, editor) => (editorRef.current = editor)}
          value={questionText}
          init={{
            height: 350,
            plugins:
              "image code table advtable lists link autolink charmap preview fullscreen math textpattern align",
            toolbar:
              "undo redo | bold italic underline | numlist bullist | alignleft aligncenter alignright | removeformat | table | link image | math | code preview fullscreen",
            image_title: true,
            automatic_uploads: true,
            file_picker_types: "image",
            file_picker_callback: filePickerCallback,
            content_style: "body { font-family:Times New Roman; font-size:16px }",
          }}
          onEditorChange={(content) => setQuestionText(content)}
        />
      </div>

      {/* Pilihan Kunci Jawaban */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="answer"
          id="trueOption"
          value="Benar"
          onChange={(e) => setAnswerKey(e.target.value)}
        />
        <label className="form-check-label" htmlFor="trueOption">
          Benar
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="answer"
          id="falseOption"
          value="Salah"
          onChange={(e) => setAnswerKey(e.target.value)}
        />
        <label className="form-check-label" htmlFor="falseOption">
          Salah
        </label>
      </div>

      {/* Tombol Simpan */}
      <button
        className="btn btn-primary mt-3"
        onClick={handleSave}
        disabled={loading}
      >
        {loading ? "Menyimpan..." : "Simpan Soal"}
      </button>
    </div>
  );
}

export default BenarSalah;
