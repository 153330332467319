import React from "react";
import { useNavigate } from "react-router-dom";

function DetailMonitor() {
  const navigate = useNavigate();
  return (
    <div className="container my-5">
      <div className="text-center">
        <div className="main-heading text-center mb-3">
          <span className="highlighted">Monitor Ujian</span>
        </div>
      </div>
      {/* Tombol Kembali */}
      <button
        className="btn btn-outline-dark mb-3"
        onClick={() => navigate(`/monitor-ujian`)}
      >
        <i className="fa-solid fa-arrow-left-long fa-fade me-2" />
        Kembali
      </button>
      <div className="section">
        <h1>tes</h1>
      </div>
    </div>
  );
}
export default DetailMonitor;
