import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  // State autentikasi dengan nilai awal dari localStorage
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem("auth_token");
    const user = JSON.parse(localStorage.getItem("user"));
    const userType = localStorage.getItem("user_type");

    return {
      isAuthenticated: !!token,
      token,
      user,
      userType,
    };
  });

  const [userData, setUserData] = useState(null); 
  const [isLoading, setIsLoading] = useState(true);

  // Cek token saat komponen pertama kali dirender
  useEffect(() => {
    if (auth.isAuthenticated && auth.token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
    }
    setIsLoading(false);
  }, [auth.isAuthenticated, auth.token]);

  // Fungsi login
  const login = async (credential, password) => {
    Swal.fire({
      title: "Sedang memproses...",
      text: "Mohon tunggu...",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    try {
      await axios.get("/sanctum/csrf-cookie");
      const csrfToken = Cookies.get("XSRF-TOKEN");
      
      // Tentukan tipe pengguna berdasarkan credential (NISN untuk siswa, email untuk pegawai)
      const isSiswa = !isNaN(credential);  // Jika credential adalah angka, berarti siswa
      const userType = isSiswa ? "siswa" : "pegawai";

      const response = await axios.post(
        "/ujian/login",
        { credential, password },
        { headers: { "X-XSRF-TOKEN": csrfToken } }
      );

      const { token, user } = response.data;

      // Simpan data di localStorage
      localStorage.setItem("auth_token", token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("user_type", userType);

      // Set token di axios
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Update auth state
      setAuth({
        isAuthenticated: true,
        token,
        user,
        userType
      });

      // Fetch data pengguna setelah login berhasil
      fetchUserData(userType, token);

      // Trigger a success message, and navigate after setting state
      Swal.fire({
        title: "Berhasil",
        text: "Anda berhasil masuk!",
        icon: "success"
      }).then(() => {
        navigate("/"); // Navigate after successful login
      });
    } catch (err) {
      Swal.fire({
        title: "Error",
        text:
          err.response?.data?.message || "Terjadi kesalahan. Coba lagi nanti.",
        icon: "error"
      });
    }
  };

const fetchUserData = async (userType, token) => {
  try {
    if (!token) return;

    setIsLoading(true); // Aktifkan loading saat mengambil data
    const endpoint = userType === "siswa" ? "/siswa" : "/pegawai";

    const response = await axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` }
    });

    const user = response.data;
    const userSiswa = response.data.user;

    // Cek apakah userType adalah siswa
    if (userType === "siswa") {
      setUserData({
        name: userSiswa.peserta_didik?.nama || "Nama tidak tersedia",
        nisn: userSiswa.peserta_didik?.nisn || "NISN tidak tersedia",
        kelas: userSiswa.peserta_didik?.kelas || "Kelas tidak tersedia",
        jenisKelamin: userSiswa.peserta_didik?.jenis_kelamin || "Jenis kelamin tidak tersedia",
        tempatLahir: userSiswa.peserta_didik?.tempat_lahir || "Tempat lahir tidak tersedia",
        tanggalLahir: userSiswa.peserta_didik?.tanggal_lahir || "Tanggal lahir tidak tersedia",
        alamat: userSiswa.peserta_didik?.alamat || "Alamat tidak tersedia",
        foto: userSiswa.peserta_didik?.foto || userSiswa.foto || "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg",
        absensi: user.absensi_ujian?.status || "Status tidak tersedia",
      });
    } else if (userType === "pegawai") {
      const tenagaPendidik = user.tenaga_pendidik;
      const tenagaKependidikan = user.tenaga_kependidikan;

      if (tenagaPendidik) {
        setUserData({
          name: tenagaPendidik?.nama || "Nama tidak tersedia",
          kodePegawai: tenagaPendidik?.kode_pegawai || "Kode Pegawai tidak tersedia",
          tempatLahir: tenagaPendidik?.tempat_lahir || "Tempat lahir tidak tersedia",
          tanggalLahir: tenagaPendidik?.tanggal_lahir || "Tanggal lahir tidak tersedia",
          alamat: tenagaPendidik?.alamat || "Alamat tidak tersedia",
          foto: tenagaPendidik?.foto || "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg",
          jabatan: tenagaPendidik?.jabatan || "Jabatan tidak tersedia",
          mataPelajaran: tenagaPendidik?.mata_pelajaran || null,
          nbm: tenagaPendidik?.nbm || "Nbm tidak tersedia",
          lulusan: tenagaPendidik?.lulusan || "Lulusan tidak tersedia",
          tmt: tenagaPendidik?.tmt || "TMT tidak tersedia"
        });
      } else if (tenagaKependidikan) {
        setUserData({
          name: tenagaKependidikan?.nama || "Nama tidak tersedia",
          kodePegawai: tenagaKependidikan?.kode_pegawai || "Kode Pegawai tidak tersedia",
          tempatLahir: tenagaKependidikan?.tempat_lahir || "Tempat lahir tidak tersedia",
          tanggalLahir: tenagaKependidikan?.tanggal_lahir || "Tanggal lahir tidak tersedia",
          alamat: tenagaKependidikan?.alamat || "Alamat tidak tersedia",
          foto: tenagaKependidikan?.foto || "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg",
          jabatan: tenagaKependidikan?.jabatan || "Jabatan tidak tersedia",
          tmt: tenagaKependidikan?.tmt || "TMT tidak tersedia"
        });
      } else {
        setUserData({
          name: "Nama tidak tersedia",
          foto: "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"
        });
      }
    }
  } catch (error) {
  // Menampilkan alert dan arahkan ke halaman login jika terjadi kesalahan
  Swal.fire({
    title: "Error",
    text: "Gagal mengambil data pengguna.",
    icon: "error",
    confirmButtonText: "Login",
    // Menambahkan callback untuk mengarahkan ke login ketika tombol diklik
  }).then(() => {
    // Bersihkan localStorage jika terjadi error
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_type");

    // Arahkan ke halaman login setelah tombol diklik
    navigate("/login");
      window.location.reload();
  });
} finally {
  setIsLoading(false);
}


};


  // Update data pengguna jika ada perubahan tipe pengguna atau token
  useEffect(() => {
    if (auth.isAuthenticated && auth.token && auth.userType) {
      fetchUserData(auth.userType, auth.token);
    }
  }, [auth.isAuthenticated, auth.token, auth.userType]);

  // Fungsi logout
  const logout = async () => {
    Swal.fire({
      title: "Sedang memproses...",
      text: "Mohon tunggu...",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    });

    try {
      await axios.post("/ujian/logout");

      // Reset state setelah logout
      setAuth({
        isAuthenticated: false,
        token: null,
        user: null,
        userType: null
      });
      setUserData(null); // Hapus data pengguna

      localStorage.removeItem("auth_token");
      localStorage.removeItem("user");
      localStorage.removeItem("user_type");

      Swal.fire({
        title: "Berhasil",
        text: "Anda berhasil logout.",
        icon: "success"
      }).then(() => navigate("/login"));
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: err.response?.data?.message || "Terjadi kesalahan saat logout.",
        icon: "error"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ auth, userData, login, logout, isLoading }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
