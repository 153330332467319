import React from "react";

function Logo() {
  return (
    <div className="header">
      <div className="logo">
        <div className="logo-icon">
          <img
            src="https://smamutas.sch.id/assets/img/logo.png"
            alt="Logo SMA Muhammadiyah Tasikmalaya"
          />
          <div className="logo-text2 d-md-none d-block mt-xl-none mt-3">
            <span className="logo-title2">MuTAS ExamPro</span>
            <span className="logo-subtitle2">SMA Muhammadiyah Tasikmalaya</span>
          </div>
        </div>

        <div className="logo-text d-md-block d-none">
          <span className="logo-title">MuTAS ExamPro</span>
          <span className="logo-subtitle">
            SMA Muhammadiyah <br /> Tasikmalaya
          </span>
        </div>
      </div>
    </div>
  );
}

export default Logo;
