import React, { useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

function Menjodohkan({ bankSoalId }) {
  const [teksSoal, setTeksSoal] = useState("Pernyataan berikut ini harus dipasangkan dengan jawabannya");
  const [penjodohan, setPenjodohan] = useState([
    { pernyataan: "", pasangan: "", bobot: "" }
  ]);
  const [loading, setLoading] = useState(false);

  const handleTambahPasangan = () => {
    setPenjodohan([...penjodohan, { pernyataan: "", pasangan: "", bobot: "" }]);
  };

  const handleHapusPasangan = (index) => {
    setPenjodohan(penjodohan.filter((_, i) => i !== index));
  };

  const handleUbahPasangan = (index, field, value) => {
    const updatedPenjodohan = [...penjodohan];
    updatedPenjodohan[index][field] = value;
    setPenjodohan(updatedPenjodohan);
  };

  const handleSimpan = async () => {
    if (!teksSoal.trim() || teksSoal.length < 10) {
      Swal.fire("Error", "Teks soal harus diisi dan lebih dari 10 karakter.", "error");
      return;
    }

    const payload = {
      bank_soal_id: bankSoalId,
      tipe_soal: "Penjodohan",
      teks_soal: teksSoal,
      penjodohan: penjodohan.map((item) => ({
        pernyataan: item.pernyataan,
        pasangan: item.pasangan,
        bobot: parseInt(item.bobot, 10)
      }))
    };

    try {
      setLoading(true);
      await axios.post("/soal", payload);
      Swal.fire("Sukses", "Soal Penjodohan berhasil disimpan!", "success");
      setTeksSoal("");
      setPenjodohan([{ pernyataan: "", pasangan: "", bobot: "" }]);
    } catch (error) {
      Swal.fire("Error", error.response?.data?.message || "Terjadi kesalahan.", "error");
    } finally {
      setLoading(false);
    }
  };

  const filePickerCallback = (cb, value, meta) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.addEventListener("change", async (e) => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("gambar", file);

      try {
        const response = await axios.post("/soal/upload-gambar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.url) {
          cb(response.data.url, { title: file.name });
        } else {
          Swal.fire("Error", "URL gambar tidak ditemukan.", "error");
        }
      } catch (err) {
        Swal.fire("Error", "Gagal mengunggah gambar.", "error");
      }
    });

    input.click();
  };

  return (
    <div>
      <h4>Soal Menjodohkan</h4>

      {/* Teks soal utama */}
      <div className="mb-3">
        <Editor
          apiKey="ns96sd0zob0oz7nfplqrmef4zgz7y88x1dmu6bskka15rwmj"
          value={teksSoal}
          init={{
            height: 200,
            plugins:
      "image code table advtable lists link autolink charmap preview fullscreen math textpattern align",
    toolbar:
      "undo redo | bold italic underline | numlist bullist | alignleft aligncenter alignright | removeformat | table | link image | math | code preview fullscreen",
                image_title: true,
                automatic_uploads: true,
                file_picker_types: "image",
                file_picker_callback: filePickerCallback,
                content_style:
                  "body { font-family:Times New Roman; font-size:16px }",
          }}
          onEditorChange={(content) => setTeksSoal(content)}
        />
      </div>
      <hr />

      {/* Form pasangan */}
      {penjodohan.map((item, index) => (
        <div className="row mb-3" key={index}>
      
          <div className="col-md-4">
            <label> Pernyataan : </label>
            <Editor
              apiKey="ns96sd0zob0oz7nfplqrmef4zgz7y88x1dmu6bskka15rwmj"
              value={item.pernyataan}
              init={{
                height: 200,
                plugins:
      "image code table advtable lists link autolink charmap preview fullscreen math textpattern align",
    toolbar:
      "undo redo | bold italic underline | numlist bullist | alignleft aligncenter alignright | removeformat | table | link image | math | code preview fullscreen",
                image_title: true,
                automatic_uploads: true,
                file_picker_types: "image",
                file_picker_callback: filePickerCallback,
                content_style:
                  "body { font-family:Times New Roman; font-size:16px }",
          }}
              onEditorChange={(content) => handleUbahPasangan(index, "pernyataan", content)}
            />
          </div>
        
          <div className="col-md-4">
            <label> Pasangan : </label>
            <Editor
              apiKey="ns96sd0zob0oz7nfplqrmef4zgz7y88x1dmu6bskka15rwmj"
              value={item.pasangan}
              init={{
                height: 200,
                plugins:
      "image code table advtable lists link autolink charmap preview fullscreen math textpattern align",
    toolbar:
      "undo redo | bold italic underline | numlist bullist | alignleft aligncenter alignright | removeformat | table | link image | math | code preview fullscreen",
                image_title: true,
                automatic_uploads: true,
                file_picker_types: "image",
                file_picker_callback: filePickerCallback,
                content_style:
                  "body { font-family:Times New Roman; font-size:16px }",
          }}
              onEditorChange={(content) => handleUbahPasangan(index, "pasangan", content)}
            />
          </div>
          <div className="col-md-2">
           <label> Bobot : </label>
            <input
              type="number"
              className="form-control"
              placeholder="Bobot"
              value={item.bobot}
              onChange={(e) => handleUbahPasangan(index, "bobot", e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-danger"
              onClick={() => handleHapusPasangan(index)}
            >
              Hapus
            </button>
          </div>
        </div>
      ))}

      {/* Tombol tambah pasangan */}
      <button className="btn btn-success me-3" onClick={handleTambahPasangan}>
        Tambah Pasangan
      </button>

      {/* Tombol simpan */}
      <button
        className="btn btn-primary"
        onClick={handleSimpan}
        disabled={loading}
      >
        {loading ? "Menyimpan..." : "Simpan Soal"}
      </button>
    </div>
  );
}

export default Menjodohkan;
