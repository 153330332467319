import React, { useState } from "react";

const Penjodohan = ({ soal, handleOptionChange }) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});

  // Handle clicking on an answer option
  const handleAnswerClick = (answer, idx) => {
    // Find the first empty question slot (index) and assign the answer
    const newAnswers = { ...selectedAnswers };
    const availableQuestions = soal.penjodohan
      .map((penjodohan, i) => (newAnswers[i] ? null : i))
      .filter((i) => i !== null);

    if (availableQuestions.length > 0) {
      const questionIdx = availableQuestions[0];
      newAnswers[questionIdx] = answer;
      setSelectedAnswers(newAnswers);
      handleOptionChange(Object.values(newAnswers)); // Send updated answers to parent
    }
  };

  // Get available answers that haven't been selected yet
  const getAvailableAnswers = () => {
    return soal.penjodohan.filter(
      (opt) => !Object.values(selectedAnswers).includes(opt.pasangan)
    );
  };

  // Remove an answer from the selected answers
  const removeAnswer = (idx) => {
    const newAnswers = { ...selectedAnswers };
    delete newAnswers[idx];
    setSelectedAnswers(newAnswers);
    handleOptionChange(Object.values(newAnswers));
  };

  return (
    <div className="penjodohan-container text-center">
      <div className="jawaban-list mb-3">
        <h5>Pilihan Jawaban</h5>
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          {/* Display available answers */}
          {getAvailableAnswers().map((opt, idx) => (
            <div
              key={idx}
              className="list-group-item border rounded p-2 bg-light"
              onClick={() => handleAnswerClick(opt.pasangan, idx)} // Handle click to select answer
            >
              <span dangerouslySetInnerHTML={{ __html: opt.pasangan }} />
            </div>
          ))}
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Pertanyaan</th>
            <th>Jawaban</th>
          </tr>
        </thead>
        <tbody>
          {soal.penjodohan
            .filter((penjodohan) => (penjodohan.pernyataan || "").trim() !== "")
            .map((penjodohan, idx) => (
              <tr key={idx}>
                <td>
                  <span
                    dangerouslySetInnerHTML={{ __html: penjodohan.pernyataan }}
                  />
                </td>
                <td className="dropzone border rounded bg-white text-center">
                  {selectedAnswers[idx] ? (
                    <div
                      className="selected-answer border rounded p-2 bg-success text-white"
                      onClick={() => removeAnswer(idx)} // Remove answer on click
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: selectedAnswers[idx],
                        }}
                      />
                    </div>
                  ) : (
                    <span className="placeholder">Pilih jawaban di sini</span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Penjodohan;
