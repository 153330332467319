import React, { Component } from "react";
import { ErrorBoundaryFallback } from "../Layouts/Components/ErrorBoundaryFallback"; // Tempat fallback error UI

class ErrorBoundary extends Component {
  state = { hasError: false, errorInfo: null };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFallback error={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
