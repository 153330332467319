import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import axios from "axios";

function Selesai() {
  const navigate = useNavigate();
  const { auth, userData } = useAuth();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    // Function to handle the API call
    const finishExam = async () => {
      try {
        const response = await axios.post(
          `/ujian/konfirmasi/selesai`,
          { credential: userData?.nisn || userData?.kode_pegawai },
          { headers: { Authorization: `Bearer ${auth?.token}` } }
        );
        console.log(response.data); // Optionally log the response
      } catch (error) {
        console.error("Error confirming exam completion:", error);
      }
    };

    // Start countdown
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Call API when countdown reaches 0
    const timeout = setTimeout(() => {
      finishExam(); // Call API on finish
      navigate("/"); // Redirect to homepage
    }, 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [auth?.token, userData?.nisn, userData?.kode_pegawai, navigate]);

  return (
    <main className="show-section overflow-hidden">
      <div className="container">
        <div className="start-wrapper">
          {/* start section */}
          <section className="start">
            {/* image */}
            <div className="start-img">
              <img
                src="https://ujian.smamutas.sch.id/assets/images/logo.png"
                alt="start1"
              />
            </div>
            {/* content */}
            <article className="start-text">
              <h3>Terimakasih!</h3>
              <p>
                Terimakasih sudah mengikuti ujian.
                <br />
                Anda akan diarahkan ke halaman utama dalam <b>{countdown} detik</b>.
              </p>
            </article>
          </section>
        </div>
      </div>
    </main>
  );
}

export default Selesai;
