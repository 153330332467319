import React, { useEffect, useState } from "react";

function Waktu() {
  const [currentDay, setCurrentDay] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      const hari = date.toLocaleDateString("id-ID", { weekday: "long" });
      const waktu = date.toLocaleTimeString("id-ID");

      setCurrentDay(`Hari ${hari}`);
      setCurrentTime(`Pukul ${waktu}`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mt-3 mb-0" style={{ textAlign: "center" }}>
      <b className="text-dark waktu">
        {currentDay}, {currentTime} WIB.
      </b>
    </div>
  );
}

export default Waktu;
