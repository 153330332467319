import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Timer = ({ initialSisaWaktu, statusUjian }) => {
  const navigate = useNavigate();

  // Parse the initial "sisa_waktu" string into total seconds for countdown
  const [timeLeft, setTimeLeft] = useState(() => {
    const match = /(\d+)\smenit\s(\d+)\sdetik/.exec(initialSisaWaktu);
    if (match) {
      const minutes = parseInt(match[1], 10);
      const seconds = parseInt(match[2], 10);
      return minutes * 60 + seconds;
    }
    return 0;
  });

  // Effect hook to handle the countdown
  useEffect(() => {
    if (statusUjian === "Ujian Selesai" || statusUjian === "Belum Dimulai" || timeLeft <= 0) {
      return;
    }

    const interval = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft, statusUjian]);

  useEffect(() => {
    if (timeLeft === 0 && statusUjian !== "Ujian Selesai") {
      Swal.fire({
        title: "Waktu Habis",
        text: "Waktu ujian telah habis. Silakan klik tombol 'Selesai' untuk menyelesaikan ujian.",
        icon: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Selesai",
      }).then(() => {
        navigate("/ujian/selesai");
      });
    }
  }, [timeLeft, statusUjian, navigate]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div>
      {statusUjian === "Ujian Selesai" ? (
        <p>Ujian telah selesai dilaksanakan.</p>
      ) : statusUjian === "Belum Dimulai" ? (
        <p>Ujian belum dimulai.</p>
      ) : timeLeft > 0 ? (
        <p>{minutes} menit {seconds} detik</p>
      ) : (
        <p>Waktu ujian telah habis.</p>
      )}
    </div>
  );
};

export default Timer;
