import React from "react";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="logo-loader" />
      <span className="loading-text">Memuat data...</span>
    </div>
  );
};

export default Loading;
