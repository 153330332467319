import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import Swal from "sweetalert2";
import Loading from "../Layouts/Components/Loading";
import PilihanGanda from "./Components/PilihanGanda";
import BenarSalah from "./Components/BenarSalah";
import Penjodohan from "./Components/Penjodohan";
import { useUjian } from "../Context/UjianContext"; // Import useUjian hook
import Timer from "./Components/Timer";

function HalamanUjian() {
  const { auth, userData } = useAuth();
  const { id: tokenUjian } = useParams();
  const navigate = useNavigate();
  const {
    soalData,
    currentSoalIndex,
    setCurrentSoalIndex,
    answeredQuestions,
    setAnsweredQuestions,
    loading,
    fetchSoalData,
    error,
    ujian,
    fetchJadwalUjian,
    updateJawabanSiswa
  } = useUjian();

  const [selectedUjian, setSelectedUjian] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const currentSoal = soalData[currentSoalIndex];
  // Fungsi untuk mengubah jawaban siswa
const handleOptionChange = useCallback(
  (option) => {
    if (selectedOption === option) return; // Jika opsi yang sama dipilih, hentikan proses

    // Perbarui opsi yang dipilih
    setSelectedOption(option);

    // Perbarui state answeredQuestions
    const updatedAnsweredQuestions = [...answeredQuestions];
    updatedAnsweredQuestions[currentSoalIndex] = option !== null; // Tandai soal ini sebagai dijawab jika opsi dipilih
    setAnsweredQuestions(updatedAnsweredQuestions);

    // Perbarui jawaban di localStorage
    const savedJawaban = JSON.parse(localStorage.getItem("jawaban_siswa") || "{}");
    savedJawaban[currentSoal.id_soal] = String(option);
    localStorage.setItem("jawaban_siswa", JSON.stringify(savedJawaban));

    // Kirim jawaban ke server untuk sinkronisasi
    updateJawabanSiswa(currentSoal.id_soal, String(option), tokenUjian);
  },
  [answeredQuestions, currentSoalIndex, selectedOption, tokenUjian, updateJawabanSiswa]
);




  useEffect(() => {
    // Hanya fetch jadwal ujian jika ujian belum ada dan sedang tidak loading
    if (ujian?.length === 0 && !loading) {
      fetchJadwalUjian();
    }

    // Setujui selectedUjian hanya jika ditemukan perubahan
    const foundUjian = ujian?.find((item) => item.token_ujian === tokenUjian);
    if (foundUjian && foundUjian !== selectedUjian) {
      setSelectedUjian(foundUjian);
    }

    // Fetch soalData hanya jika kondisi terpenuhi dan soalData kosong
    if (userData?.nisn && userData?.kelas && tokenUjian && soalData.length === 0) {
      fetchSoalData(tokenUjian);
    }

    // Sinkronisasi answeredQuestions berdasarkan jawaban_siswa
    if (soalData.length > 0) {
      const initialAnsweredQuestions = soalData.map((soal) => Boolean(soal.jawaban_siswa));

      // Membandingkan array dengan JSON.stringify untuk mencegah loop tak terbatas
      if (JSON.stringify(initialAnsweredQuestions) !== JSON.stringify(answeredQuestions)) {
        setAnsweredQuestions(initialAnsweredQuestions);
      }
    }
  }, [
    ujian,
    tokenUjian,
    userData,
    fetchJadwalUjian,
    fetchSoalData,
    soalData,
    loading,
    selectedUjian, // Menambahkan selectedUjian sebagai dependensi untuk pembaruan yang tepat
    answeredQuestions, // Menambahkan answeredQuestions agar tidak terjebak dalam loop
  ]);

  // Sinkronisasi jawaban siswa saat soal berubah
// Sinkronisasi jawaban siswa saat soal berubah
useEffect(() => {
  // Muat jawaban siswa dari localStorage saat pertama kali halaman dimuat
  const savedJawaban = JSON.parse(localStorage.getItem("jawaban_siswa") || "{}");
  const initialOption = savedJawaban[currentSoal?.id_soal] || currentSoal?.jawaban_siswa || null;
  
  // Hanya set ulang jika selectedOption belum disesuaikan
  if (selectedOption !== initialOption) {
    setSelectedOption(initialOption);
  }
}, [currentSoal, selectedOption]);


  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (soalData.length === 0) {
    return <div>Soal tidak ditemukan.</div>;
  }

  if (!selectedUjian) {
    Swal.fire({
      title: "Ujian tidak ditemukan",
      text: "Ujian yang Anda coba akses tidak valid.",
      icon: "error",
    }).then(() => navigate("/"));
    return null; // Pastikan komponen berhenti render
  }

const handleFinish = () => {
  const unansweredQuestions = soalData.filter(
    (_, index) => !answeredQuestions[index]
  );

  if (unansweredQuestions.length > 0) {
    // Jika ada soal yang belum terjawab
    Swal.fire({
      title: "Masih ada jawaban yang kosong",
      text: `Anda masih memiliki ${unansweredQuestions.length} soal yang belum dijawab. Apakah Anda yakin ingin menyelesaikan ujian?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Selesaikan Ujian",
      cancelButtonText: "Kembali",
    }).then((result) => {
      if (result.isConfirmed) {
        // Proses selesai ujian meskipun ada jawaban kosong
        finishExam();
      }
    });
  } else {
    // Jika semua soal telah dijawab
    Swal.fire({
      title: "Semua soal telah dijawab",
      text: "Apakah Anda yakin ingin menyelesaikan ujian?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Selesaikan Ujian",
      cancelButtonText: "Kembali",
    }).then((result) => {
      if (result.isConfirmed) {
        // Proses selesai ujian
        finishExam();
      }
    });
  }
};

// Fungsi untuk menyelesaikan ujian
const finishExam = () => {
  Swal.fire({
    title: "Ujian selesai",
    text: "Terimakasih Jawaban Anda sudah terkirim.",
    icon: "success",
  }).then(() => {
    navigate("/ujian/selesai");
  });
};


  return (
    <main-ujian className="overflow-hidden">
      <div className="row h-100">
        {/* Navigasi soal */}
        <div className="slideup side col-md-3 order-c">
          <div className="side-inner">
            <h5 className="text-center">Navigasi Soal</h5>
            <div className="table-responsive">
              <div className="table table-hover p-4 text-center">
              <tbody>
  {Array.from({ length: Math.ceil(soalData.length / 5) }, (_, rowIndex) => (
    <tr key={rowIndex}>
      {Array.from({ length: 5 }, (_, colIndex) => {
        const questionIndex = rowIndex * 5 + colIndex;
        if (questionIndex >= soalData.length) return null;

        return (
          <td key={colIndex}>
           <button
  className={`btn ${currentSoalIndex === questionIndex ? "btn-warning" : ""} 
  ${answeredQuestions[questionIndex] ? "btn-success" : "btn-outline-primary"}`}
  onClick={() => {
    setCurrentSoalIndex(questionIndex); // Pindah ke soal tertentu
  }}
>
  {questionIndex + 1}
</button>

          </td>
        );
      })}
    </tr>
  ))}
</tbody>



              </div>
            </div>
          </div>
        </div>

        {/* Konten ujian */}
        <div className="slidedown col-md-9 h-100">
          <div className="wrapper-ujian">
            <div className="contact-ujian text-center me-5">
              <i className="fa-solid fa-clock" />
              <article-ujian>
                <h5>Sisa Waktu</h5>
                <Timer
                  initialSisaWaktu={selectedUjian?.sisa_waktu}
                  statusUjian={selectedUjian?.status}
                />
              </article-ujian>
            </div>

            <form id="steps" method="post" className="show-section-ujian" noValidate="true">
              <div className="step-count-ujian mt-4">
                Soal {currentSoalIndex + 1} / {soalData.length}
              </div>
              <section className="steps-ujian">
                <span className="main-heading-ujian">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: currentSoal.teks_soal,
                    }}
                  ></p>
                </span>
                <div className="line-break-ujian" />
                <fieldset
                  className="form-ujian"
                  id={`step${currentSoalIndex + 1}`}
                >
                  <div className="row justify-content-space-between revealfield">
                    <div className="text-field-ujian">
                      {currentSoal.tipe_soal === "Pilihan Ganda" && (
                        <PilihanGanda
                          soal={currentSoal}
                          selectedOption={selectedOption}
                          handleOptionChange={handleOptionChange}
                        />
                      )}
                      {currentSoal.tipe_soal === "Benar-Salah" && (
                        <BenarSalah
                          selectedOption={selectedOption}
                          handleOptionChange={handleOptionChange}
                        />
                      )}
                      {currentSoal.tipe_soal === "Penjodohan" && (
                        <Penjodohan
                          soal={currentSoal}
                          handleOptionChange={handleOptionChange}
                        />
                      )}
                    </div>
                  </div>
                </fieldset>

                <div className="next-prev-button-ujian mt-4">
                  <button
                    type="button"
                    className="prev-ujian"
                    onClick={() =>
                      setCurrentSoalIndex((prev) => Math.max(prev - 1, 0))
                    }
                    disabled={currentSoalIndex === 0}
                  >
                    Sebelumnya
                  </button>
                 <button
  type="button"
  className="next-ujian"
  onClick={() => {
    if (selectedOption !== null) {
      // Tandai soal ini sebagai dijawab
      const updatedAnsweredQuestions = [...answeredQuestions];
      updatedAnsweredQuestions[currentSoalIndex] = true;
      setAnsweredQuestions(updatedAnsweredQuestions);
    }

    // Pindah ke soal berikutnya atau selesai
    if (currentSoalIndex === soalData.length - 1) {
      handleFinish();
    } else {
      setCurrentSoalIndex((prev) => Math.min(prev + 1, soalData.length - 1));
    }
  }}
>
  {currentSoalIndex === soalData.length - 1 ? "Selesai" : "Selanjutnya"}
</button>


                </div>
              </section>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-partical-2">
        <img
          src="https://ujian.smamutas.sch.id/assets/images/partical1.png"
          alt="Partical"
        />
      </div>
    </main-ujian>
  );
}

export default HalamanUjian;
