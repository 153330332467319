import React from "react";
import Waktu from "./Components/Waktu";
import Logo from "./Components/Logo";
import MenuData from "./Components/MenuData";

const MainLayouts = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <Waktu />
        <Logo />
        <div className="overflow-hidden">
          <div className="container mb-5">
            <div className="row justify-content-center">
              <MenuData />

              <div className="card ps-md-5 pe-md-5 pb-md-5 p-2">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayouts;
