import React from "react";

const PilihanGanda = ({ soal, selectedOption, handleOptionChange }) => {
  return (
    <div className="pilihan-ganda-container">
      {soal.pilihan.map((pilihan, idx) => (
        <a
          key={pilihan.id}
          href="#"
          className={`option-btn ${
            selectedOption === String(pilihan.id) ? "selected bg-jawaban" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleOptionChange(pilihan.id); // Langsung gunakan handleOptionChange dari induk
          }}
        >
          <div
            className={`focus-ujian ${
              selectedOption === String(pilihan.id) ? "selected bg-jawaban" : ""
            } form-text-ujian d-flex delay-100ms revealfield`}
          >
            <div className="text-field-icon-ujian revealfield">
              <i
                className={`fa-solid fa-${String.fromCharCode(97 + idx)} revealfield`}
              />
            </div>
            <div className="text-field-input-ujian revealfield">
              <span
                dangerouslySetInnerHTML={{
                  __html: pilihan.teks_pilihan,
                }}
              />
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default PilihanGanda;
