import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import Swal from "sweetalert2";

function Menu() {
  const navigate = useNavigate();
  const { auth, logout } = useAuth(); // Mendapatkan auth dan logout dari AuthContext
  const [activeMenu, setActiveMenu] = useState(""); // Default to main page
  const [isStudent, setIsStudent] = useState(false);

  useEffect(() => {
    // Tentukan tipe user berdasarkan state auth dari context
    setIsStudent(auth.userType === "siswa");
  }, [auth]);

  const handleMenuClick = (path) => {
    setActiveMenu(path);
    if (path === "/keluar") {
      showLogoutConfirmation();
    } else {
      navigate(path);
    }
  };

  const showLogoutConfirmation = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Anda yakin ingin logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, logout",
      cancelButtonText: "Batal"
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  return (
    <div className="col-md-6 col-sm-12 right-section">
      <div className="right-section-inner">
        <div className="wrapper show-section">
          <section className="steps step-1">
            <div className="steps-inner">
              <div className="main-heading text-center">
                <span className="highlighted">Menu</span>
              </div>
              <hr />

              <div id="step1" className="d-flex justify-content-center">
                <div className="d-flex flex-wrap justify-content-center gap-3">
                  {/* Halaman Utama */}
                  <div
                    className={`menu-item p-3 text-center ${
                      activeMenu === "/" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("/")}
                    style={{ minWidth: "80px", cursor: "pointer" }}
                  >
                    <i className="fas fa-home fa-2x d-md-inline d-none text-primary" />
                    <i className="fas fa-home fa-1x d-md-none d-inline text-primary" />
                    <label className="d-block mt-2">Halaman Utama</label>
                  </div>

                  {/* Menu lainnya hanya untuk pegawai */}
                  {!isStudent && (
                    <>
                      {/* Bank Soal */}
                      <div
                        className={`menu-item p-3 text-center ${
                          activeMenu === "/bank-soal" ? "active" : ""
                        }`}
                        onClick={() => handleMenuClick("/bank-soal")}
                        style={{ minWidth: "80px", cursor: "pointer" }}
                      >
                        <i className="fas fa-book fa-2x d-md-inline d-none text-success" />
                        <i className="fas fa-book fa-1x d-md-none d-inline text-success" />
                        <label className="d-block mt-2">Bank Soal</label>
                      </div>

                      {/* Daftar Peserta */}
                      <div
                        className={`menu-item p-3 text-center ${
                          activeMenu === "/data-peserta" ? "active" : ""
                        }`}
                        onClick={() => handleMenuClick("/data-peserta")}
                        style={{ minWidth: "80px", cursor: "pointer" }}
                      >
                        <i className="fas fa-users fa-2x d-md-inline d-none text-primary" />
                        <i className="fas fa-users fa-1x d-md-none d-inline text-primary" />
                        <label className="d-block mt-2">Data Peserta</label>
                      </div>

                      {/* Daftar Ujian */}
                      <div
                        className={`menu-item p-3 ms-md-4 ms-0 text-center ${
                          activeMenu === "/daftar-ujian" ? "active" : ""
                        }`}
                        onClick={() => handleMenuClick("/daftar-ujian")}
                        style={{ minWidth: "80px", cursor: "pointer" }}
                      >
                        <i className="fas fa-clipboard-list fa-2x d-md-inline d-none text-warning" />
                        <i className="fas fa-clipboard-list fa-1x d-md-none d-inline text-warning" />
                        <label className="d-block mt-2">Daftar Ujian</label>
                      </div>

                      {/* Monitoring Ujian */}
                      <div
                        className={`menu-item p-3 text-center ${
                          activeMenu === "/monitor-ujian" ? "active" : ""
                        }`}
                        onClick={() => handleMenuClick("/monitor-ujian")}
                        style={{ minWidth: "80px", cursor: "pointer" }}
                      >
                        <i className="fas fa-eye fa-2x d-md-inline d-none text-info" />
                        <i className="fas fa-eye fa-1x d-md-none d-inline text-info" />
                        <label className="d-block mt-2">Monitor Ujian</label>
                      </div>

                      {/* Riwayat Ujian */}
                      <div
                        className={`menu-item p-3 text-center ${
                          activeMenu === "/riwayat-ujian" ? "active" : ""
                        }`}
                        onClick={() => handleMenuClick("/riwayat-ujian")}
                        style={{ minWidth: "80px", cursor: "pointer" }}
                      >
                        <i className="fas fa-history fa-2x d-md-inline d-none text-danger" />
                        <i className="fas fa-history fa-1x d-md-none d-inline text-danger" />
                        <label className="d-block mt-2">Riwayat Ujian</label>
                      </div>

                      {/* Riwayat Peserta */}
                      <div
                        className={`menu-item p-3 text-center ${
                          activeMenu === "/riwayat-peserta" ? "active" : ""
                        }`}
                        onClick={() => handleMenuClick("/riwayat-peserta")}
                        style={{ minWidth: "80px", cursor: "pointer" }}
                      >
                        <i className="fas fa-user-clock fa-2x d-md-inline d-none text-success" />
                        <i className="fas fa-user-clock fa-1x d-md-none d-inline text-success" />
                        <label className="d-block mt-2">Log Aktivitas</label>
                      </div>
                    </>
                  )}

                  {/* Keluar */}
                  <div
                    className={`menu-item p-3 text-center ${
                      activeMenu === "/keluar" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("/keluar")}
                    style={{ minWidth: "80px", cursor: "pointer" }}
                  >
                    <i className="fas fa-sign-out-alt fa-2x d-md-inline d-none text-danger" />
                    <i className="fas fa-sign-out-alt fa-1x d-md-none d-inline text-danger" />
                    <label className="d-block mt-2">Keluar</label>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Menu;
