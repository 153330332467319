import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/id";

const DaftarUjian = () => {
  const [daftarUjian, setDaftarUjian] = useState([]);
  const [kelasOptions, setKelasOptions] = useState([]);
  const [soalOptions, setSoalOptions] = useState([]);

  // Fetch daftar ujian awal
  const fetchData = async () => {
    try {
      const response = await axios.get("/daftarujian");
      setDaftarUjian(response.data.daftar_ujian);
    } catch (error) {
      Swal.fire("Error", "Gagal memuat data ujian.", "error");
    }
  };

  // Fetch daftar kelas dan soal
  const fetchOptions = async () => {
    try {
      const [kelasRes, soalRes] = await Promise.all([
        axios.get("/daftar-kelas"),
        axios.get("/banksoal")
      ]);
      setKelasOptions(kelasRes.data.daftar_kelas);
      const validatedSoal = soalRes.data.filter(
      (soal) => soal.status_validasi === "Tervalidasi"
    );
    setSoalOptions(validatedSoal);
    } catch (error) {
      Swal.fire("Error", "Gagal memuat data pilihan.", "error");
    }
  };
  
  const generateToken = async (id) => {
  try {
    const response = await axios.post(`/daftarujian/generate-token/${id}`);
    Swal.fire('Sukses', `Token berhasil dibuat dan soal telah tersimpan!: ${response.data.token}`, 'success');
    fetchData(); // Refresh the data after generating the token
  } catch (error) {
    Swal.fire('Error', 'Gagal membuat token ujian.', 'error');
  }
};


  // Handle form for adding/editing ujian
const handleFormUjian = async (ujian = null) => {
  const isEdit = !!ujian;

  const { value: formValues } = await Swal.fire({
    title: isEdit ? "Edit Jadwal Ujian" : "Buat Jadwal Ujian Baru",
    html: `
      <div class="mb-3">
        <label for="kelas" class="form-label"><b>Pilih Kelas</b></label><br />
        ${kelasOptions
          .map(
            (kelas, index) => `
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" name="kelas" id="kelas${index}" value="${kelas}" ${
                  ujian?.kelas?.includes(kelas) ? "checked" : ""
                }>
                <label class="form-check-label" for="kelas${index}">${kelas}</label>
              </div>
            `
          )
          .join("")}
      </div>

      <div class="mb-3">
        <label for="soal_ujian" class="form-label"><b>Pilih Soal</b></label>
        <select id="soal_ujian" class="swal2-input mb-3">
          <option value="" disabled selected>Pilih Soal</option>
          ${soalOptions
            .map(
              (soal) =>
                `<option value="${soal.id}" ${
                  ujian?.soal_ujian === soal.id ? "selected" : ""
                }>${soal.nama_soal}</option>`
            )
            .join("")}
        </select>
      </div>

      <div class="mb-3">
        <label for="tanggal_ujian" class="form-label">Tanggal Ujian</label>
        <input id="tanggal_ujian" type="date" class="swal2-input mb-3" value="${
          ujian?.tanggal_ujian
            ? moment(ujian.tanggal_ujian).format("YYYY-MM-DD")
            : ""
        }">
      </div>

      <div class="mb-3">
        <label for="waktu_ujian" class="form-label">Waktu Ujian</label>
        <input id="waktu_ujian" type="time" class="swal2-input mb-3" value="${
          ujian?.waktu_ujian ? ujian.waktu_ujian.slice(0, 5) : ""
        }">
      </div>

      <div class="mb-3">
        <label for="durasi_ujian" class="form-label">Durasi Ujian (Menit)</label>
        <input id="durasi_ujian" type="number" class="swal2-input mb-3" placeholder="Masukkan durasi ujian dalam menit" value="${
          ujian?.durasi_ujian !== undefined ? ujian.durasi_ujian : ""
        }">
      </div>
    `,
    preConfirm: () => {
      // Mengambil nilai semua kelas yang dipilih dengan checkbox
      const kelas = Array.from(document.querySelectorAll('input[name="kelas"]:checked')).map(input => input.value);
      const soal_ujian = document.getElementById("soal_ujian").value;
      const tanggal_ujian = document.getElementById("tanggal_ujian").value;
      const waktu_ujian = document.getElementById("waktu_ujian").value;
      const durasi_ujian = document.getElementById("durasi_ujian").value;

      // Validasi untuk memastikan bahwa semua field diisi
      if (kelas.length === 0 || !soal_ujian || !tanggal_ujian || !waktu_ujian || !durasi_ujian) {
        Swal.showValidationMessage("Semua field harus diisi!");
      }

      return { kelas, soal_ujian, tanggal_ujian, waktu_ujian, durasi_ujian };
    }
  });

  if (formValues) {
    // Mengambil nama soal berdasarkan ID
    const soalSelected = soalOptions.find(soal => soal.id === formValues.soal_ujian);
    const soalNama = soalSelected ? soalSelected.nama_soal : "Tidak ada soal yang dipilih";

    // Konfirmasi pemilihan kelas dan soal
    Swal.fire({
      title: "Konfirmasi Pilihan",
      text: `Kelas yang dipilih: ${formValues.kelas.join(", ")}`,
      icon: "info",
      confirmButtonText: "Lanjutkan"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            ...formValues,
            waktu_ujian: `${formValues.waktu_ujian}:00` // Menambahkan detik
          };

          if (isEdit) {
            await axios.put(`/daftarujian/${ujian.id}`, payload);
            Swal.fire("Sukses", "Ujian berhasil diperbarui.", "success");
          } else {
            await axios.post("/daftarujian", {
              ...payload,
              status_token: "Token Belum Aktif",
              status_ujian: "Belum Dimulai"
            });
            Swal.fire("Sukses", "Ujian berhasil ditambahkan.", "success");
          }
          fetchData(); // Refresh data
        } catch (error) {
          Swal.fire("Error", "Gagal menyimpan data ujian.", "error");
        }
      }
    });
  }
};

const cekDataToken = async (id) => {
  try {
    const response = await axios.get(`/daftarujian/data-token/${id}`);
    const data = response.data;

    if (data.status === "gagal") {
      Swal.fire("Error", data.pesan, "error");
      return;
    }

    const {
      token,
      status_token,
      sisa_waktu_token,
      status_ujian,
      detail_ujian,
      data_kelas,
      waktu_berlaku,
      waktu_berakhir,
    } = data.data;

    // Tabel detail token ujian
    const detailTokenTable = `
      <table class="mt-2" style="width: 100%; border-collapse: collapse;" border="1">
        <tbody>
          <tr>
            <td><b>Token :</b></td>
            <td>${token || "Belum Ada Token"}</td>
          </tr>
          <tr>
            <td><b>Status Token :</b></td>
            <td>${status_token || "Tidak Tersedia"}</td>
          </tr>
          <tr>
            <td><b>Status Ujian :</b></td>
            <td>${status_ujian || "Tidak Tersedia"}</td>
          </tr>
        </tbody>
      </table>
    `;

    // Tabel informasi ujian dan soal
    const detailUjianTable = `
      <table class="mt-2" style="width: 100%; border-collapse: collapse;" border="1">
        <tbody>
          <tr>
            <td><b>Nama Soal :</b></td>
            <td>${detail_ujian.soal_ujian?.nama_soal || "Tidak Tersedia"}</td>
          </tr>
          <tr>
            <td><b>Mata Pelajaran :</b></td>
            <td>${detail_ujian.soal_ujian?.mata_pelajaran || "Tidak Tersedia"}</td>
          </tr>
          <tr>
            <td><b>Tanggal Ujian :</b></td>
            <td>${detail_ujian.tanggal_ujian}</td>
          </tr>
          <tr>
            <td><b>Durasi :</b></td>
            <td>${detail_ujian.durasi}</td>
          </tr>
          <tr>
            <td><b>Waktu Berlaku :</b></td>
            <td>${waktu_berlaku.tanggal}, ${waktu_berlaku.pukul}</td>
          </tr>
          <tr>
            <td><b>Waktu Berakhir :</b></td>
            <td>${waktu_berakhir.tanggal}, ${waktu_berakhir.pukul}</td>
          </tr>
        </tbody>
      </table>
    `;

    // Tabel daftar siswa per kelas
    const kelasTables = data_kelas
      .map((kelas) => {
        const muridRows = kelas.murid
          .map(
            (murid, index) => `
          <tr>
            <td>${index + 1}</td>
            <td>${murid.nisn}</td>
            <td>${murid.nama}</td>
          </tr>
        `
          )
          .join("");

        return `
          <table class="table table-bordered table-hover mt-4 text-center" style="width: 100%; margin-bottom: 20px ;margin-top: 20px; border-collapse: collapse;" border="1">
            <thead>
              <tr style="background-color: #f2f2f2;">
                <th colspan="3" style="text-align: center;">Kelas ${kelas.kelas}</th>
              </tr>
              <tr>
                <th style="width: 10%;">No</th>
                <th>NISN</th>
                <th>Nama Siswa</th>
              </tr>
            </thead>
            <tbody>
              ${muridRows}
            </tbody>
          </table>
        `;
      })
      .join("");

    // Gabungkan semua tabel dan tampilkan dalam Swal.fire
    Swal.fire({
      title: "Detail Token Ujian",
      html: `
        <h5>Detail Token</h5>
        ${detailTokenTable}
        <h5 class="mt-3">Informasi Ujian</h5>
        ${detailUjianTable}
        <h5 class="mt-3">Daftar Siswa per Kelas</h5>
        ${kelasTables}
      `,
      icon: "info",
      width: "700px", // Lebar modal untuk menampilkan data lebih rapi
    });
  } catch (error) {
    Swal.fire("Error", "Gagal memuat detail token dan daftar siswa.", "error");
  }
};




  // Hapus ujian
  const hapusUjian = async (id) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Data yang dihapus tidak dapat dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Hapus!",
      cancelButtonText: "Batal"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/daftarujian/${id}`);
          Swal.fire("Dihapus!", "Data ujian telah dihapus.", "success");
          fetchData();
        } catch (error) {
          Swal.fire("Error", "Gagal menghapus data ujian.", "error");
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
    fetchOptions();
  }, []);

  return (
    <div className="col-md-12 col-sm-12">
      <div className="left-section-inner">
        <div className="text-center mb-2">
          <div className="main-heading text-center mb-3">
            <span className="highlighted">Daftar Ujian</span>
          </div>
          <p>
            Lihat dan Atur Daftar Ujian yang akan dilaksanakan berdasarkan
            urutan waktu paling dekat memulai ujian.
          </p>
        </div>
        <center>
          <button
            className="btn btn-primary btn-sm mb-3"
            onClick={() => handleFormUjian()}
          >
            <i className="fa fa-plus" /> Buat Ujian
          </button>
        </center>
        <div className="table-responsive">
          <table className="table table-bordered text-center">
            <thead className="thead-dark">
              <tr>
                <th>Kelas</th>
                <th>Peserta</th>
                <th>Soal</th>
                <th>Waktu</th>
                <th>Durasi</th>
                <th>Token</th>
                <th>Aksi</th>
              </tr>
            </thead>
<tbody>
  {daftarUjian.length > 0 ? (
    daftarUjian
     
      .map((ujian) => (
        <tr key={ujian.id}>
          <td>
            {ujian.kelas.map((kelas, index) => (
              <span key={index} className="kelas-item">
                {kelas}
                {index < ujian.kelas.length - 1 && ", "}
              </span>
            ))}
          </td>
          <td>{ujian.jumlah_peserta} Siswa</td>
          <td>
            {ujian.bank_soal
              ? `${ujian.bank_soal.nama_soal} (${ujian.bank_soal.mata_pelajaran})`
              : "Tidak ada soal"}
          </td>
         <td>
  {moment(ujian.tanggal_ujian).locale('id').format('D MMMM YYYY')}, <br />
  Pukul {moment(ujian.waktu_ujian, 'HH:mm:ss').format('HH:mm')} WIB
</td>
          <td>{ujian.durasi_terformat}</td>

          <td>
            {ujian.token_ujian ? (
              // If token_ujian exists, show "Cek Detail"
              <button
                className={`btn btn-sm ${
                  ujian.status_token === "Token Aktif"
                    ? "btn-success"
                    : ujian.status_token === "Token Belum Aktif"
                    ? "btn-warning"
                    : "btn-secondary"
                }`}
                onClick={() => cekDataToken(ujian.id)}
              >
                Cek Detail
              </button>
            ) : moment().isAfter(moment(`${ujian.tanggal_ujian} ${ujian.waktu_ujian}`)) ? (
              // If token_ujian doesn't exist and exam time has passed, show "Kadaluarsa"
              <div>
                <button className="btn btn-sm btn-secondary" disabled>
                  Token Kadaluarsa
                </button>
                <div>
                  <small>
                    Kadaluarsa:
                    {moment(`${ujian.tanggal_ujian} ${ujian.waktu_ujian}`).format(
                      "dddd, DD MMMM YYYY HH:mm"
                    )}
                  </small>
                </div>
              </div>
            ) : (
              // If token_ujian doesn't exist and exam time hasn't passed, show "Generate"
              <button
                className="btn btn-sm btn-success"
                onClick={() => generateToken(ujian.id)}
              >
                Aktivasi
              </button>
            )}
          </td>

          <td>
            <button
              className="btn btn-sm btn-primary me-1"
              onClick={() => handleFormUjian(ujian)}
            >
              Edit
            </button>
            <div className="d-md-none d-sm-block">
              <hr />
            </div>
            <button
              className="btn btn-sm btn-danger ms-1"
              onClick={() => hapusUjian(ujian.id)}
            >
              Hapus
            </button>
          </td>
        </tr>
      ))
  ) : (
    <tr>
      <td colSpan="8" className="text-center">
        Tidak ada ujian
      </td>
    </tr>
  )}
</tbody>

          </table>
        </div>
      </div>
    </div>
  );
};

export default DaftarUjian;
