import React, { useEffect, useState } from "react";
import { useAuth } from "../Context/AuthContext";
import Loading from "../Layouts/Components/Loading";

function Content() {
  const { auth, userData, isLoading } = useAuth();

  // Get the greeting based on the current time
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 10) return "Selamat Pagi";
    if (hour < 14) return "Selamat Siang";
    if (hour < 18) return "Selamat Sore";
    return "Selamat Malam";
  };

  const greeting = getGreeting();

  // Render loading state until user data is loaded
  if (isLoading || !userData) {
    return <Loading />;
  }

  return (
    <div className="col-md-6 col-sm-12">
      <div className="left-section-inner">
        <div className="wrapper">
          <section>
            <div className="steps-inner">
              <div className="main-heading text-center">
                <span className="highlighted">{greeting}!</span>
                <span className="d-md-block d-none">{userData?.name}</span>
              </div>

              <div className="left-section-text mt-3">
                <p className="d-md-block d-none">
                  Selamat datang di platform ujian daring SMA Muhammadiyah
                  Tasikmalaya. Aplikasi ini merupakan sarana ujian yang aman dan
                  mudah diakses. Melalui platform ini, Anda dapat mengelola dan
                  mengikuti ujian dengan nyaman. Semoga proses belajar dan ujian
                  Anda berjalan lancar, dan mencapai hasil yang memuaskan.
                  Sukses selalu!
                </p>

                <div className="card radius-30 mt-4 p-3 d-flex align-items-center">
                  <img
                    src={`https://smamutas.sch.id${userData.foto}`}
                    alt="User Avatar"
                    className="rounded-circle"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover"
                    }}
                  />
                  <div className="user-info mt-3 text-center">
                    <h5>{userData?.name}</h5>
                    <span>
                      {userData?.jabatan}{" "}
                      {userData?.mataPelajaran && <> {userData?.mataPelajaran}</>}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Content;
