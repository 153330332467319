import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext";
import Swal from "sweetalert2";

const UjianContext = createContext();

export const UjianProvider = ({ children }) => {
  const { userData, auth } = useAuth();
  const [ujian, setUjian] = useState(() => {
    const cachedData = localStorage.getItem(`ujian_data_${userData?.kelas}`);
    return cachedData ? JSON.parse(cachedData) : [];
  });
  const [soalData, setSoalData] = useState([]);
  const [currentSoalIndex, setCurrentSoalIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [jawabanSiswa, setJawabanSiswa] = useState({}); // State untuk jawaban siswa
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");


const updateJawabanSiswa = (soalId, jawaban, tokenUjian) => {
  // Validasi input
  if (!tokenUjian) {
    console.error("Token ujian tidak tersedia.");
    Swal.fire({
      title: "Error",
      text: "Token ujian tidak ditemukan!",
      icon: "error",
    });
    return;
  }

  if (!soalId) {
    console.error("Soal ID tidak tersedia.");
    Swal.fire({
      title: "Error",
      text: "Soal ID tidak ditemukan!",
      icon: "error",
    });
    return;
  }

  if (!jawaban || jawaban === "undefined") {
    console.error("Jawaban siswa tidak valid.");
    Swal.fire({
      title: "Error",
      text: "Jawaban siswa tidak valid!",
      icon: "error",
    });
    return;
  }

  // Payload untuk API
  const payload = {
    nisn: userData.nisn,
    token_ujian: tokenUjian,
    soal_id: soalId,
    jawaban_siswa: jawaban,
  };

  // Kirim payload ke backend
  axios
    .post("/ujian/proses/update-jawaban", payload, {
      headers: { Authorization: `Bearer ${auth.token}` },
    })
    .then((response) => {
      console.log("Jawaban berhasil disimpan:", response.data);
      
    })
    .catch((error) => {
      console.error("Gagal menyimpan jawaban:", error.response?.data?.message || error.message);
      Swal.fire({
        title: "Gagal",
        text: "Terjadi kesalahan saat menyimpan jawaban.",
        icon: "error",
      });
    });
};



  const fetchJadwalUjian = async () => {
    if (!userData?.kelas) {
      setError("Kelas tidak ditemukan.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await axios.get(`/daftarujian/${userData.kelas}`);
      const daftarUjian = response.data?.daftar_ujian;

      if (!daftarUjian) {
        throw new Error("Data ujian tidak ditemukan.");
      }

      const cachedData = localStorage.getItem(`ujian_data_${userData.kelas}`);
      const existingData = cachedData ? JSON.parse(cachedData) : [];
      const isSameData = JSON.stringify(daftarUjian) === JSON.stringify(existingData);

      if (!isSameData) {
        setUjian(daftarUjian);
        localStorage.setItem(`ujian_data_${userData.kelas}`, JSON.stringify(daftarUjian));
      }
    } catch (err) {
      console.error("Error fetching jadwal ujian:", err);
      setError(err.response?.data?.message || "Gagal memuat data ujian.");
    } finally {
      setLoading(false);
    }
  };

  const fetchSoalData = async (tokenUjian) => {
    if (!userData?.nisn || !userData?.kelas || !tokenUjian) {
      setError("Data pengguna atau token ujian tidak lengkap.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `/ujian/load-soal/${userData.nisn}/${tokenUjian}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
          params: {
            kelas: userData.kelas,
            nisn: userData.nisn,
            token_ujian: tokenUjian,
          },
        }
      );
      setSoalData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching soal data:", error);
      setError(
        error.response?.data?.message || "Terjadi kesalahan saat mengambil data soal."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.kelas) {
      fetchJadwalUjian();
    }

    const savedJawaban = localStorage.getItem("jawaban_siswa");
    if (savedJawaban) {
      setJawabanSiswa(JSON.parse(savedJawaban)); // Memuat jawaban yang disimpan di localStorage
    }
  }, [userData]);

  return (
    <UjianContext.Provider
      value={{
        ujian,
        soalData,
        currentSoalIndex,
        setCurrentSoalIndex,
        answeredQuestions,
        setAnsweredQuestions,
        jawabanSiswa, // Menambahkan jawabanSiswa
        setJawabanSiswa,
        loading,
        error,
        fetchSoalData,
        updateJawabanSiswa, // Menambahkan fungsi updateJawabanSiswa
      }}
    >
      {children}
    </UjianContext.Provider>
  );
};

export const useUjian = () => {
  return useContext(UjianContext);
};
