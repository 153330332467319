import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUjian } from "../Context/UjianContext";
import { useAuth } from "../Context/AuthContext";
import Swal from "sweetalert2";
import Loading from "../Layouts/Components/Loading";
import Timer from "./Components/Timer";
import axios from "axios";

const DetailUjian = () => {
  const { id: tokenUjian } = useParams();
  const navigate = useNavigate();
  const { auth, userData } = useAuth();
  const { ujian, fetchJadwalUjian } = useUjian();
  const [selectedUjian, setSelectedUjian] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ujian.length === 0) {
      fetchJadwalUjian();
    }
    const foundUjian = ujian.find((item) => item.token_ujian === tokenUjian);
    setSelectedUjian(foundUjian);
  }, [ujian, tokenUjian, fetchJadwalUjian]);

   const handleMasukUjian = async () => {
    Swal.fire({
      title: "Konfirmasi Masuk Ujian",
      text: "Setelah masuk ujian, kamu tidak dapat keluar. Pastikan sudah siap.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Masuk Ujian",
      cancelButtonText: "Batal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const response = await axios.post(
            `/ujian/konfirmasi`,
            { credential: userData?.nisn || userData?.kode_pegawai },
            { headers: { Authorization: `Bearer ${auth?.token}` } }
          );

       
          navigate(`/ujian/peraturan/${tokenUjian}`);
        } catch (error) {
          Swal.fire(
            "Gagal!",
            error.response?.data?.message || "Terjadi kesalahan.",
            "error"
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  if (!selectedUjian) return <Loading />;

  return (
    <div className="container mt-3">
      <div className="text-center">
        <div className="main-heading text-center mb-3">
          <span className="highlighted">Detail Informasi Ujian</span>
        </div>
        <p>
          Lihat Informasi Ujian dan Data Peserta yang mengikuti ujian dengan
          mudah.
        </p>
      </div>
      <button
        className="btn btn-outline-primary"
        onClick={() => navigate(`/`)}
      >
        <i className="fa-solid fa-arrow-left-long fa-fade me-2" />
        Kembali
      </button>
      <div className="row mt-3">
        <div className="col-md-4 col-sm-12 center-section">
          <div className="center-section-inner">
            <div className="wrapper show-section">
              <section className="steps step-1">
                <div className="steps-inner">
                  <div className="main-heading2 text-center">
                    <span className="highlighted">Informasi Ujian</span>
                  </div>
                  <hr />
                  <div id="step1" className="justify-content-center">
                    <div className="table-responsive mt-3">
                      <table className="table table-striped text-start">
                        <tbody>
                          <tr>
                            <th>Sisa Waktu Ujian</th>
                            <td>
                              <Timer
                                initialSisaWaktu={selectedUjian.sisa_waktu}
                                statusUjian={selectedUjian.status}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>Token</th>
                            <td>{selectedUjian.token_ujian}</td>
                          </tr>
                          <tr>
                            <th>Status Token</th>
                            <td>{selectedUjian.status_token}</td>
                          </tr>
                          <tr>
                            <th>Kelas</th>
                            <td>{selectedUjian.kelas}</td>
                          </tr>
                          <tr>
                            <th>Mata Pelajaran</th>
                            <td>{selectedUjian.mata_pelajaran}</td>
                          </tr>
                          <tr>
                            <th>Guru Mata Pelajaran</th>
                            <td>{selectedUjian.guru_mata_pelajaran}</td>
                          </tr>
                          <tr>
                            <th>Tanggal dan Waktu Ujian</th>
                            <td>
                              {selectedUjian.tanggal_mulai}, {selectedUjian.waktu_mulai}
                            </td>
                          </tr>
                          <tr>
                            <th>Durasi Ujian</th>
                            <td>{selectedUjian.durasi}</td>
                          </tr>
                          <tr>
                            <th>Status Ujian</th>
                            <td>{selectedUjian.status}</td>
                          </tr>
                          <tr>
                            <th>Tanggal dan Waktu Selesai</th>
                            <td>
                              {selectedUjian.tanggal_selesai}, {selectedUjian.waktu_selesai}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 center-section mt-md-5 mt-0">
          <div className="center-section-inner">
            <div className="wrapper">
              <div className="col-md-12 col-sm-12 center-section d-flex justify-content-center align-items-center">
                <section className="steps text-center p-3">
                  <div className="mt-md-1 mt-0">
                    {selectedUjian.status === "Sedang Berlangsung" && (
                      <>
                       {userData?.absensi === "Tidak Hadir" ? (
      <>
        <h6 className="text-danger">
          *Klik disini untuk masuk dan memulai mengerjakan soal!
        </h6>
        <br />
        <button
          className="btn btn-danger mt-1"
          onClick={handleMasukUjian}
          disabled={selectedUjian.status_token === "Token Kadaluarsa"}
        >
          Masuk Ujian
        </button>
      </>
      ) : userData?.absensi === "Sedang Ujian" ? (
      <>
      <h6 className="text-danger">
          *Klik disini untuk masuk kembali dan mengerjakan soal!
        </h6>
        <br />
            <button
          className="btn btn-danger mt-1"
          onClick={handleMasukUjian}
          disabled={selectedUjian.status_token === "Token Kadaluarsa"}
        >
          Masuk Ujian
        </button></>
    ) : (
      <h6 className="text-danger">
        Anda sudah mengikuti ujian ini sebelumnya.
      </h6>
    )}
                   
                      
                      </>
                    )}
                    {selectedUjian.status === "Ujian Selesai" && (
                      <h5 className="text-success">
                        Ujian telah selesai dilaksanakan.
                      </h5>
                    )}
                    {selectedUjian.status === "Belum Dimulai" && (
                      <h6 className="text-primary">Ujian akan dimulai pada pukul {selectedUjian.waktu_mulai} WIB</h6>
                    )}
                  </div>
                </section>
              </div>
              <section className="steps">
                <div className="steps-inner">
                  <div className="main-heading2 text-center mb-3">
                    <span className="highlighted">Data Peserta</span>
                  </div>
                  <hr />
                  <div className="text-center">
                    <img
                      src={
                        userData?.foto ||
                        "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"
                      }
                      alt="User Avatar"
                      className="rounded-circle"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="table-responsive mt-3">
                      <table className="table text-start">
                        <tbody>
                          <tr>
                            <th>NISN</th>
                            <td>{userData?.nisn}</td>
                          </tr>
                          <tr>
                            <th>Nama</th>
                            <td>{userData?.name}</td>
                          </tr>
                          <tr>
                            <th>Kelas</th>
                            <td>{userData?.kelas}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailUjian;
